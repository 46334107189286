import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { EmptyDialogComponent } from '../../helpers/dialogs/empty-dialog/empty-dialog.component';
import { serializeError } from 'serialize-error';

@Component({
	selector: 'app-admin-login',
	templateUrl: './admin-login.component.html',
	styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
	
	loginForm: any;
	isLoading: boolean = true;
	
	constructor(
		private fb         : FormBuilder,
		private authService: AuthenticationService,
		private router     : Router,
		private snackbar   : MatSnackBar,
		private dialog     : MatDialog,
	) { }
	
	ngOnInit(): void {
		this.makeForm();
		// this.authService.login('Willem-Jan', '123');
	}
	
	makeForm() {
		this.loginForm = this.fb.group({
			name    : new FormControl('', [Validators.required]),
			password: new FormControl('', [Validators.required])
		})
		this.isLoading = false;
	}
	
	async login() {
		var form = this.loginForm;
		if(form.valid) {
			var name = form.get("name");
			var pass = form.get("password");
			try {
				var login = await this.authService.login(name.value, pass.value);
				if(!login['error'])  { 
					let role = this.authService.getRole();
					let isQuizAdmin = this.authService.isQuizAdmin();
					if(role == "admin" || role == "super_admin" || isQuizAdmin) {
						this.router.navigateByUrl("/admin");
					} else {
						this.snackbar.open("Je hebt niet voldoende rechten", 'Sluiten', {duration: 3000});
					}
				}
			} catch(err) {
				// console.log(err);
				var message = this.snackbar.open('Er ging iets mis', 'details', {duration: 4000});
				message.afterDismissed().subscribe(info => {
					if(info.dismissedByAction == true) {
						var err2 = serializeError(err);
						err2.stack = err2.stack.replace(/(?:\r\n|\r|\n)/g, '\n');
						const dialogRef = this.dialog.open(EmptyDialogComponent,{
							data: {
								err: err2
							}
						});
					}
				})
			}
		}
	}
}