<!-- <app-spinner *ngIf="isLoading" class="qm__spinner"></app-spinner> -->
<div class="form__wrapper" *ngIf="!isLoading && quiz.status['round']">
	<mat-card>
		<mat-card-title>{{quiz.name}}</mat-card-title>
		<mat-card-subtitle>{{ round['title'] }}, Vraag {{ quiz.status['question'] }} van {{ round['questions'] }}</mat-card-subtitle>
		<!-- <mat-card-subtitle>{{ quiz.activeRound }}, Vraag [RONDE] van [VRAAG]</mat-card-subtitle> -->
		<mat-card-content>
			<form [formGroup]="adminForm">
				<h3 class="form__sectionTitle">Status</h3>
				<mat-divider [inset]="true"></mat-divider>
				<mat-form-field class="form__field" appearance="fill">
					<mat-label>Status</mat-label>
					<!-- <mat-select (selectionChange)="changeServerStatus()" formControlName="status"> -->
					<mat-select (selectionChange)="changeServerStatus()" formControlName="status" [(value)]="quiz.status['status']">
						<mat-option value="End">End</mat-option>
						<mat-option value="Live">Live</mat-option>
						<mat-option value="Ready">Ready</mat-option>
						<mat-option value="Offline">Offline</mat-option>
					</mat-select>
				</mat-form-field>
				<h3 class="form__sectionTitle">Vraag</h3>
				<mat-divider [inset]="true"></mat-divider>
				<div class="form__field">
					<a class="form__button" [disabled]="quiz.question <= 0" mat-flat-button (click)="toQuestion('previous')" color="primary">Vorige vraag</a>
					<a class="form__button" [disabled]="quiz.question > quiz.status['round']['questions']" mat-flat-button (click)="toQuestion('next')" color="primary">Volgende vraag</a>
				</div>
				<h3 class="form__sectionTitle">Ronde</h3>
				<mat-divider [inset]="true"></mat-divider>
				<div class="form__field">
					<a class="form__button" [disabled]="round['order'] <= 1" mat-flat-button (click)="toRound('previous')" color="primary">Vorige ronde</a>
					<a class="form__button" *ngIf="round['order'] < quiz.rounds.length" mat-flat-button (click)="toRound('next')" color="primary">Volgende ronde</a>
					<a class="form__button" *ngIf="round['order'] == quiz.rounds.length" mat-flat-button (click)="toRound('next')" color="primary">Quiz beëindigen</a>
				</div>
				<h3 class="form__sectionTitle">Synchroniseren</h3>
				<mat-divider [inset]="true"></mat-divider>
				<div class="form__field">
					<a class="form__button" *ngIf="!syncDialogOpen" mat-flat-button (click)="openSyncDialog()" color="primary">Open dialoog</a>
					<a class="form__button" *ngIf="syncDialogOpen" mat-flat-button (click)="closeSyncDialog()" color="primary">Sluit dialoog</a>
					<a class="form__button" [disabled]="!syncDialogOpen" mat-flat-button (click)="setServerSyncTime()" color="primary">Synchroniseer</a>
					<a class="form__button" mat-stroked-button (click)="clearSyncDelay()" color="primary">Synchronisatie wissen</a>
				</div>
			</form>
		</mat-card-content>
	</mat-card>
</div>       