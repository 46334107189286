import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
	selector: 'app-review-more-info',
	templateUrl: './review-more-info.component.html',
	styleUrls: ['./review-more-info.component.scss']
})
export class ReviewMoreInfoComponent implements OnInit {
	
	number : number = 0;
	round  : number = 0;
	answer : string ="";
	form   : FormGroup;
	users  : string[] = [];
	constructor(
		private formBuilder: FormBuilder,
		private api: ApiService,
		public  dialogRef: MatDialogRef<ReviewMoreInfoComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
	) { }
	
	ngOnInit(): void {
		this.round  = this.data.round;
		this.number = this.data.number;
		this.answer = this.data.answer;
		this.makeForm();
		this.getUsers();
	}

	makeForm() {
		this.form = this.formBuilder.group({
			points: new FormControl(
				this.data.points, [
					//todo: checken of dit niet mooier kan
					Validators.pattern('(^[0-9]+$|^[0-9]+((\.|\,)[0-9]+$))')
				]
			)
		})
	}

	async getUsers() {
		try {
			let result = await <Promise<string[]>>this.api.request("get", "answers/get_users", {params: {
				number: this.number,
				answer: this.answer,
				round: this.round
			}});
			if(!result['errors']) {
				this.users = result['data'];
			}
		} catch {
			// do nothing..
		}
	}

	submit(form) {
		if(typeof form.value.points === 'string') {
			form.value.points = form.value.points.replace(",",".");
		}
		this.dialogRef.close(`${form.value.points}`);
	  }
	
}
