<div class="register__wrapper" [style]="customCss">
    <h2 mat-dialog-title>{{title}}</h2>
    <ng-container *ngIf="!sent">
        <mat-dialog-content>
            <span [innerHTML]="text"></span>

            <div *ngIf="errorMsg" class="form__error">
                {{errorMsg}}
            </div>

            <form [formGroup]="form" (submit)="submitForm()">

                <mat-form-field *ngFor="let field of formFields">
                    <mat-label>{{field.label}}</mat-label>
                    <input matInput [type]="field.type" [formControlName]="field.formControl">
                    <mat-error *ngIf="form.controls[field.formControl].errors">
                        <div *ngIf="form.controls[field.formControl].errors.required">
                            Dit veld is verplicht
                        </div>
                        <div *ngIf="form.controls[field.formControl].errors.minlength">
                            Dit veld moet minimmaal {{form.controls[field.formControl].errors.minlength.requiredLength}} tekens bevatten
                        </div>
                        <div *ngIf="form.controls[field.formControl].errors.validatorMatchWith">
                            De waarden komen niet overeen.
                        </div>
                        <div *ngIf="form.controls[field.formControl].errors.forbiddenName">
                            Deze gebruikersnaam is al in gebruik
                        </div>
                        <div *ngIf="form.controls[field.formControl].errors.email">
                            Dit is geen geldig emailadres
                        </div>
                    </mat-error>
                </mat-form-field>
            </form>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <a 
                mat-button 
                class='form__extra-link' 
                *ngIf="links['text']" 
                [routerLink]="links['url']"
            >
                {{links['text']}}
            </a>
            <button 
                mat-raised-button 
                color="primary"
                tabindex="-1" 
                (click)="submitForm()"
                [disabled]="form.invalid"
            >
                {{buttonText}}
            </button>
        </mat-dialog-actions>
        <a 
            *ngIf="hintUrl"
            [routerLink]="hintUrl"
            class="hintLink"
        >
            {{ hintText }}
        </a>
    </ng-container>

    <ng-container *ngIf="sent">
        <mat-dialog-content>
            {{ doneText }}
        </mat-dialog-content>
        <mat-dialog-actions>
            <a 
                mat-button 
                class='form__extra-link' 
                *ngIf="doneBtnText && doneBtnLink" 
                [routerLink]="doneBtnLink"
            >
                {{doneBtnText}}
            </a>
        </mat-dialog-actions>
    </ng-container>
</div>