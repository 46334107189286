import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [MatButtonModule, MatCheckboxModule, MatToolbarModule,MatCardModule,MatFormFieldModule,MatSelectModule,MatDividerModule,MatDialogModule,MatExpansionModule,MatProgressSpinnerModule,MatTableModule,MatSidenavModule,MatListModule,MatInputModule,MatMenuModule,MatSnackBarModule,MatTooltipModule,MatStepperModule,MatIconModule,MatTabsModule,MatSlideToggleModule,MatSliderModule,DragDropModule,MatDatepickerModule,MatNativeDateModule,MatAutocompleteModule,MatRadioModule],
    exports: [MatButtonModule, MatCheckboxModule, MatToolbarModule,MatCardModule,MatFormFieldModule,MatSelectModule,MatDividerModule,MatDialogModule,MatExpansionModule,MatProgressSpinnerModule,MatTableModule,MatSidenavModule,MatListModule,MatInputModule,MatMenuModule,MatSnackBarModule,MatTooltipModule,MatStepperModule,MatIconModule,MatTabsModule,MatSlideToggleModule,MatSliderModule,DragDropModule,MatDatepickerModule,MatNativeDateModule,MatAutocompleteModule,MatRadioModule],
})
export class MaterialModule { }