import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { AuthenticationService, QuizRoles } from 'src/app/services/authentication/authentication.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SearchUserModalComponent } from './search-user-modal/search-user-modal.component';

@Component({
	selector: 'app-participants',
	templateUrl: './participants.component.html',
	styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent implements OnInit {
	
	participants: object[] = [];
	isLoading = true;
	displayedColumns: string[] = ['name', 'email'];
	
	constructor(
		private api: ApiService,
		public quiz: QuizService,
		private user: AuthenticationService,
		private snackbar: MatSnackBar,
		public dialog: MatDialog,
	) { }
	
	async ngOnInit(): Promise<void> {
		await this.getParticipants();
		this.setColumns();
		this.isLoading = false;
	}
	
	async getParticipants() {
		let result = await this.api.request("get", "participation/quiz/" + this.quiz.id);
		if(!result['errors']) {
			this.participants = result['data'];
		}
	}

	setColumns() {
		if(this.user.getQuizRole() == QuizRoles.Admin) {
			this.displayedColumns.push("role");
		}
	}

	async changeRole(id, event) {
		var result = await this.api.request("put", "participation/"+id, {body: {role: event.value}});
		if(!result['errors']) {
			this.snackbar.open('Deelnemer is bijgewerkt', 'sluiten', {duration: 3000})
		}
	}

	openDialog(): void {
		const dialogRef = this.dialog.open(SearchUserModalComponent, {
		  	width: '350px',
		});
	
		dialogRef.afterClosed().subscribe(result => {
			this.getParticipants();
		});
	}
}
