import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api/api.service';
import { SpinnerService } from '../spinner/spinner.service';

const SESSION_STORAGE_KEY: string = "quizId";
export enum quizStatus {
  "OFFLINE" = "Offline",
  "READY"   = "Ready",
  "LIVE"    = "Live",
  "END"     = "End"
}

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  id            : number;
  name          : string;
  round         : object;
  question      : number     = 1;
  status        : quizStatus = quizStatus.OFFLINE;
  date          : Date;
  rounds        : object[];
  isLoading     : boolean = true;

  constructor(
    private api: ApiService,
  ) { }

  get() {
    this.isLoading = true;
		if(!sessionStorage.getItem("quizId")) {
			sessionStorage.setItem("quizId", environment.default_quiz_id);
		}
    let quizId = sessionStorage.getItem(SESSION_STORAGE_KEY);
    return new Promise(async (res, rej) => {
      var quiz = await <object>this.api.request("get", "quiz/"+quizId);
      this.id               = quiz['id'];
      this.name             = quiz['name'];
      this.question         = quiz['question'];
      this.status           = quiz['status'];
      this.date             = quiz['date'];
      this.rounds           = <object[]>quiz['rounds'];

      this.round = quiz['rounds'].filter(obj => { return obj.id == quiz['round']; })[0];
      this.isLoading = false;
      res(quiz);
    });
  }
}
