<h1 mat-dialog-title>Deelnemer toevoegen</h1>
<div mat-dialog-content>
    Zoek een gebruiker aan de hand van de teamnaam. Kies vervolgens een rol voor deze gebruiker.
    <form [formGroup]="searchForm">
        <mat-form-field class="search-user__input">
            <mat-label>Teamnaam</mat-label>
            <input matInput formControlName="name" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let user of users" [value]="user">
                  {{user.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="search-user__input">
            <mat-label>Rol</mat-label>
            <select matNativeControl  formControlName="role">
                <option value="USER">Gebruiker</option>
                <option value="REVIEWER">Nakijker</option>
                <option value="MODERATOR">Moderator</option>
                <option value="ADMIN">Admin</option>
            </select>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-flat-button>Annuleren</button>
    <!-- <button mat-flat-button (click)="onNoClick()">Annuleren</button> -->
    <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="addParticipant()">Toevoegen</button>
</div>   