import { Component, OnInit } from '@angular/core';
import { StatusService } from 'src/app/services/status/status.service';
import { ApiService } from 'src/app/services/api/api.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-round-overview-questions',
  templateUrl: './round-overview-questions.component.html',
  styleUrls: ['./round-overview-questions.component.scss']
})
export class RoundOverviewQuestionsComponent implements OnInit {
	
	questions       : object[]   = [];
	displayedColumns: string[] = ["status", "name"];
	isLoading       : boolean  = false;
	
	constructor(
		public quiz: StatusService,
		public api: ApiService,
		public spinner: SpinnerService
	) { }
	
	ngOnInit(): void {
    this.isLoading = true;
		this.setQuestions();
    this.isLoading = false;
	}

	setQuestions() {
		for(let i = 0; i < this.quiz.rounds.length; i++) {
			let id    = this.quiz.rounds[i]['id'];
			let title = this.quiz.rounds[i]['title']
			this.questions[id + "_" + title] = [];
		}
  }
  
  getStatus(reviewed, done) {
    reviewed  = parseInt(reviewed);
    done      = parseInt(done);
    if(reviewed == 0) {
      return "new";
    }
    if(reviewed > 0 && reviewed < done) {
      return "inProgress";
    }
    if(reviewed == done) {
      return "done";
    }
  }
	
	async getQuestions(id, title) {
		if(this.questions[id + "_" + title].length == 0) {
			this.questions[id + "_" + title] = await <Promise<object[]>>this.api.request("get", "round/questions/"+id);
		}
	}

}
