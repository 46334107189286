<div class="login__wrapper">

    <div class="form__wrapper">
    <mat-card *ngIf="!isLoading">
        <mat-card-title>Admin inlog</mat-card-title>
        <mat-card-subtitle>Inloggen met je hoofd!</mat-card-subtitle>
        <mat-card-content>
            <!-- <div *ngIf="errorMsg != ''" class="form__error">
                {{errorMsg}}
            </div> -->
        <form [formGroup]="loginForm">
            <mat-form-field>
                <input matInput type="text" formControlName="name" placeholder="Gebruikersnaam">
            </mat-form-field>
            <mat-form-field>
                <input matInput type="password" formControlName="password" placeholder="Wachtwoord">
            </mat-form-field>
            <!-- <button mat-flat-button (click)="signIn()" color="primary" Inloggen</button> -->
            <button mat-flat-button color="primary" (click)="login()" [disabled]="loginForm.invalid">Inloggen</button>
        </form>
        </mat-card-content>
    </mat-card>
    </div>
</div>