<mat-card>
    <mat-card-title>Deelnemers</mat-card-title>
    <mat-card-subtitle>{{ participants.length }} deelnemers</mat-card-subtitle>
    <mat-card-content>
        <table mat-table *ngIf="!isLoading" [dataSource]="participants" class="full-width mat-table-alternate mat-elevation-z0">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Naam </th>
                <td mat-cell *matCellDef="let element">{{ element.user.name }} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element">{{ element.user.email }} </td>
            </ng-container>
              
            <ng-container matColumnDef="role">
                <th class="partcipants-overview__role-cell" mat-header-cell *matHeaderCellDef> Rol </th>
                <td class="partcipants-overview__role-cell" mat-cell *matCellDef="let element">
                    <mat-form-field>
                        <mat-select [value]="element.role" (selectionChange)="changeRole(element.id, $event)">
                            <mat-option value="Admin">Administrator</mat-option>
                            <mat-option value="Moderator">Moderator</mat-option>
                            <mat-option value="Reviewer">Nakijker</mat-option>
                            <mat-option value="User">Gebruiker</mat-option>
                        </mat-select>
                      </mat-form-field>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-flat-button color="primary" (click)="openDialog()">Deelnemer toevoegen</button>
    </mat-card-actions>
</mat-card>