import { Component, OnInit, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { StatusService } from 'src/app/services/status/status.service';
import { takeUntil, filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReviewMoreInfoComponent } from '../review-more-info/review-more-info.component';

@Component({
	selector: 'app-review-question-component',
	templateUrl: './review-question-component.component.html',
	styleUrls: ['./review-question-component.component.scss']
})
export class ReviewQuestionComponentComponent implements OnInit {
	
	user            : object;
	answers         : object[];
	round           : object;
	totalPoints     : number;
	isLoading       : boolean 	= true;
	questionNumber	: string;
	displayedColumns: string[] 	= ["amount", "answer", "action-correct", "action-incorrect"];
	device          : string;
	mouseTimeout;
	formDialog;
	private unsubscribe = new Subject;
	ReviewMoreInfoDialog: MatDialogRef<ReviewMoreInfoComponent>;
	
	constructor(
		private activatedRoute: ActivatedRoute,
		private api           : ApiService,
		private router		  : Router,
		public  spinner       : SpinnerService,
		public  quiz          : StatusService,
		private dialog        : MatDialog,
	) { }
	
	ngOnInit(): void {
		this.watchUrl();
		if(window.innerWidth > 768) {
			this.displayedColumns.push('action-more');
			this.device = "desktop";
		} else {
			this.device = "mobile";
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if(window.innerWidth > 768) {
			if(this.displayedColumns.indexOf('action-more') == -1) {
				this.displayedColumns.push('action-more');
			}
			this.device = "desktop";
		} else {
			let pos = this.displayedColumns.indexOf('action-more');
			if(pos > -1) {
				this.displayedColumns.splice(pos, 1);
			}
			this.device = "mobile";
		}
	}
	
	watchUrl() {
		this.activatedRoute.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(async (params) => {
			this.isLoading = true;
			this.questionNumber = params.get("number");
			this.getRound(params.get("round"));
			await this.getAnswers(this.questionNumber);
			// await this.getAnswers();
			// this.setTotalPoints();
			this.isLoading = false;
		});
	}
	
	getRound(roundId) {
		if(!this.round || this.round['id'] != roundId) {
			this.round = this.quiz.rounds.filter(rounds => { return rounds['id'] == roundId })[0];
		}
	}
	
	getAnswers(number) {
		return new Promise(async (res, rej) => {
			this.answers = await <Promise<object[]>>this.api.request("get", "answers/question/"+this.round['id']+"/"+number);
			res(true);
		});
	}
	
	reviewEverything(points) {
		for(let i = 0; i < this.answers.length; i++) {
			this.answers[i]['points'] = points;
		}
	}

	openDialog(row):void {
		this.ReviewMoreInfoDialog = this.dialog.open(ReviewMoreInfoComponent, {
			data: {
				round : this.round['id'],
				number: row['number'],
				points: row['points'],
				answer: row['answer'],
			}
		});
		
		this.ReviewMoreInfoDialog.afterClosed().pipe(
			filter(points => points)
		).subscribe(points => {
			row.points = parseFloat(points);
		})
	}
	
	reviewQuestion(question, answer, points) {
		var theAnswer = this.answers.filter(obj => {
			return (obj['number'] == question) && (obj['answer'] == answer);
		})[0];

		theAnswer['points'] = points;
	}
	
	async savePoints(action) {
		await this.api.request("put", "answers/"+this.round['id']+"/", 
		{
			"body": {
				"method": "3",
				"answers": this.answers
			}
		});
		if(action == "overview") {
			this.router.navigateByUrl("/admin/nakijken/vraag");
		}
		return true;
	}
	
	longPress(row) {
		if(this.device == 'mobile') {
			this.mouseTimeout = setTimeout(() => {
				this.openDialog(row);
			}, 750);
		}
	}

	mouseUp() {
		clearTimeout(this.mouseTimeout);
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
	
}
	