import { Component, OnInit } from '@angular/core';
import { QuizRoles } from 'src/app/services/authentication/authentication.service';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { debounceTime, switchMap, map, distinctUntilChanged } from 'rxjs/operators';
import { QuizService } from 'src/app/services/quiz/quiz.service';

@Component({
	selector: 'app-search-user-modal',
	templateUrl: './search-user-modal.component.html',
	styleUrls: ['./search-user-modal.component.scss']
})
export class SearchUserModalComponent implements OnInit {
	
	roles = QuizRoles;
	searchQuery: string;
	searchForm: FormGroup;
	users: object[];
	
	constructor(
		private api: ApiService,
		private fb: FormBuilder,
		private quiz: QuizService,
		// public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
		// @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
	) { }
	
	ngOnInit(): void {
		this.makeForm();
		// this.fetchUsers();
		// console.log(this.searchForm.get('name').valueChanges);
		this.searchForm.get('name')
		.valueChanges
		.pipe(
			debounceTime(300),
			distinctUntilChanged()
			)
			.subscribe(async value => { 
				let result = await <Promise<object[]>>this.api.request("get", "user/search", {params: {query: value}});
				this.users = result['data']
			}
		)
	}
		
	makeForm() {
		this.searchForm = this.fb.group({
			name: new FormControl('', []),
			role: new FormControl("USER", []),
		})
	}
	
	fetchUsers() {
		let result = this.searchForm
		.get('name')
		.valueChanges
		.pipe(
			debounceTime(300),
			switchMap(value => this.api.request("get", "/api/users/search", {body: {query: this.searchQuery}}) )
		);
	}

	displayFn(user: object): string {
		return user && user['name'] ? user['name'] : '';
	}

	async addParticipant() {
		var user = this.searchForm.get("name").value;
			user = user['id'];
		await this.api.request("post", "participation", {
			body: {
				user: user,
				quiz: this.quiz.getId(),
				role: this.searchForm.get('role').value
			}
		})
	}
}
			