import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

const jwt = new JwtHelperService();

@Component({
	selector: 'app-edit-account',
	templateUrl: './edit-account.component.html',
	styleUrls: ['./edit-account.component.scss']
})
export class EditAccountComponent implements OnInit {
	
	isLoading : boolean = true;
	userId    : string;
	name      : string = "";
	email     : string = "";
	error     : string;
	pending   : boolean = false;
	done      : boolean = false;
	editForm  : FormGroup
	isAdmin   : boolean = false;
	baseUrl   : string = "/";
	passwordUrl: string = "";
	
	constructor(
		private api     : ApiService,
		private fb      : FormBuilder,
	) { }
	
	async ngOnInit(): Promise<void> {
		await this.getUser();
		this.makeForm();
		this.isLoading = false;
		
		let regex = new RegExp(/^\/admin\//);
		if (regex.test(window.location.pathname)) {
			this.baseUrl = "/admin/";
			this.passwordUrl = this.baseUrl + "/account/wachtwoord";
		} else {
			this.passwordUrl = this.baseUrl + "reset_wachtwoord";
		}
	}

	getUser():Promise<void> {
		return new Promise(async (res, rej) => {
			try {
				let decoded_token = jwt.decodeToken(localStorage.getItem("jwt_token"));
				let user    = await this.api.request("get", "user/"+decoded_token.id);
				this.userId = decoded_token.id;
				this.name   = user['data']['name'];
				this.email  = user['data']['email'];
				res();
			} catch {
				// default error handler
			}
		});
	}

	makeForm():void {
		this.editForm = this.fb.group({
			email: new FormControl(this.email, [
				Validators.required,
				Validators.email
			]),
			name: new FormControl(this.name, [
				Validators.required,
				Validators.minLength(3)
			]),
		});
	}

	async submitForm():Promise<void> {
		this.pending = true;
		this.error = "";
		try {
			await this.api.request("post", "user/"+this.userId, {body: this.editForm.value, ignoreErrors: true});
			let name = this.editForm.controls['name'].value;
			localStorage.setItem("name", name);
			this.pending = false;
			this.done    = true;
		} catch(err) {
			setTimeout(() => {
				this.pending = false;
			}, 2000);
			this.error   = err['message'];
		}
	}
	
}
