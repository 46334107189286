import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { QuizService } from 'src/app/services/quiz/quiz.service';

@Component({
	selector: 'app-results',
	templateUrl: './results.component.html',
	styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
	
	results         : object[] = [];
	displayedColumns: string[] = ["nr", "user_name"];
	isLoading       : boolean = true;
	showRounds 		: boolean = true;

	constructor(
		private api: ApiService,
		private quiz: QuizService
	) { }
	
	ngOnInit(): void {
		this.setColumns();
		this.getResults();
		this.displayedColumns.push("_total");
	}
	
	setColumns() {
		for(let i = 0; i < this.quiz.rounds.length; i++) {
			this.displayedColumns.push(this.quiz.rounds[i]['title']);
		}
	}

	getRoundNr(title) {
		return this.quiz.rounds.filter(obj => {
			return obj['title'] == title;
		})[0]['order'];
	}

	toggleRoundColumns() {
		this.displayedColumns = ["nr", "user_name"];
		this.showRounds = !this.showRounds;
		if(this.showRounds) {
			this.setColumns();
		}
		this.displayedColumns.push("_total");
	}

	async getResults() {
		var apiResults = await this.api.request("get", "/answers/result/"+this.quiz.id);

		for(let user in apiResults) {
			this.results.push(<object>apiResults[user]);
		}

		this.results.sort((a, b) => {
			if(a['total'] > b['total']) {
			  	return -1;
			}
			if(b['total'] > a['total']) {
			  	return 1;
			}
			// bij gelijke stand, kijken naar de tijd
			if(a['time'] > b['time']) {
			  	return 1;
			}
			if(b['time'] > a['time']) {
			  	return -1;
			}
			return 0;
		});

		this.isLoading = false;
	}
}
