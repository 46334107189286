import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-splash',
	templateUrl: './splash.component.html',
	styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit, OnDestroy {
	@ViewChild("splash__wrapper") private splash__wrapper: ElementRef<HTMLElement>;
	
	title     : string;
	text  	  : string;
	viltje    : string;
	small     : boolean = false;
	isLoading : boolean = true;
	private onDestroy$: Subject<void> = new Subject<void>();
	
	constructor(
		private quiz: QuizService,
		private socket: WebSocketService
	) { }
	
	onResized(event) {
		this.small = event.newWidth < 768 ? true : false;
	}

	ngOnInit(): void {
		this.setTexts();
		this.socket
			.listener("current question")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.quiz.status['question'] = <number>data;
			})
		;

		this.socket
			.listener("current round")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.quiz.status['round'] =  <number>data;
				this.setTexts();
			})
		;

		this.socket
			.listener("current status")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.setTexts();
			})
		;

		this.socket
			.listener("end quiz")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.setTexts();
			})
		;
		this.isLoading = false;
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	
	setTexts() {
		let round = this.quiz.rounds.filter(obj => {
			return obj['id'] == this.quiz.status['round'];
		})[0];

		switch(this.quiz.status['status']) {
			case "Offline":
				this.title  = this.quiz.texts['offline_title'];
				this.text   = this.quiz.texts['offline_text'];
				this.viltje = this.quiz.texts['offline_viltje'];
			break;
			case "Ready":
				this.title  = this.quiz.texts['ready_title'];
				this.text   = this.quiz.texts['ready_text'];
				this.viltje = this.quiz.texts['ready_viltje'];
			break;
			case "Live":
				this.title  = round['title'];
				this.text   = "";
				this.viltje = "";
			break;
			case "End":
				this.title  = this.quiz.texts['end_title'];
				this.text   = this.quiz.texts['end_text'];
				this.viltje = this.quiz.texts['end_viltje'];
			break;
		}
	}
}
