<div class="sync-dialog__wrapper">
    <h2 mat-dialog-title>App synchroniseren</h2>

    <div *ngIf="!syncDone">
        Hieronder zie je een grote rode knop in beeld. Deze knop is bedoeld om de app met de YouTube livestream te synchroniseren.
        <span color="warn"><b>Let op!</b> Druk de knop <b>niet</b> direct in!</span>
        <br><br>
        De quizmaster in de YouTube stream vertelt wanneer je de knop in moet drukken.

        <div class="sync-dialog__countdown" *ngIf="countdown > 0">
            Het lijkt er op dat je stream veel achter loopt.<br>
            Je hebt nog {{ countdown }} seconden om te synchroniseren.
        </div>

        <a 
            (click)="syncApp()" 
            [class.sync-dialog__button--disabled]="socket.serverSyncTime == 0" 
            [matTooltipDisabled]="socket.serverSyncTime > 0"
            matTooltip="Wacht tot er wordt gezegd dat je de knop in kan drukken"
            class="sync-dialog__button"
        ></a>
        <div class="sync-dialog__button-description"></div>
    </div>
    <div *ngIf="syncDone">
        Het synchroniseren is gelukt!
    </div>
</div>