import { Component, OnInit, ViewChildren, ViewChild, ElementRef, ChangeDetectorRef, ɵConsole } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SettingService } from 'src/app/services/settings/setting.service';

@Component({
	selector: 'app-messages-bar',
	templateUrl: './messages-bar.component.html',
	styleUrls: ['./messages-bar.component.scss']
})
export class MessagesBarComponent implements OnInit {
	@ViewChild("messagesBar") private messagesBar2: ElementRef<HTMLElement>;
	// @ViewChildren('messagesBar') messagesBar;

	        isLoading        : boolean = true;
			messages  		 : object[];
			messages_buffer  : object[];
			quizId	         : string;
	private onDestroy$       : Subject<void> = new Subject<void>();

	constructor(
		private activatedRoute: ActivatedRoute,
		private api           : ApiService,
		private socket        : WebSocketService,
		public  quiz		  : QuizService,
		public settings       : SettingService
	) { }
	
	async ngOnInit() {
		this.watchUrl();
		await this.quiz.getQuiz();
		await this.settings.fetchSettings();
		await this.getMessages();
		this.messages = this.messages_buffer;
		await this.socket.connect(this.quiz.url);
		this.settings.listen();
		this.listenForMessages();
		this.isLoading = false;
		setTimeout(() => {
			this.move();
		}, 100);
	}

	watchUrl() {
		this.activatedRoute
			.paramMap
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(async (data) => {
				sessionStorage.setItem("quizId", data['params']['quizId'])
				this.quizId = data['params']['quizId'];
			}
		);
	}

	listenForMessages() {
		this.socket
			.listener("update_messages")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.getMessages();
			})
		;
	}

	getMessages() {
		return new Promise(async (res, rej) => {
			this.messages_buffer = await <Promise<object[]>>this.api.request("get", "messages/"+this.quizId, {
				params: {
					"approved": "true",
					"limit": this.settings.MESSAGES_AMOUNT
				},
			});

			var blankMessage = {
				id: 0,
				user: { name: "" },
				message: this.settings.MESSAGES_EMPTY_MESSAGE
			}

			if(this.messages_buffer.length < this.settings.MESSAGES_AMOUNT)  {
				this.messages_buffer.push(blankMessage);
				this.messages_buffer.push(blankMessage);
			}
			res();
		});
	}

	// getSettings() {
	// 	return new Promise(async (res, rej) => {
	// 		this.quiz.settings = await <object[]>this.api.request("get", "settings/"+this.quizId);
	// 		res();
	// 	});
	// }

	getWidth() {
		const parentElement = this.messagesBar2.nativeElement;
		const firstChild = parentElement.children[0];
		return firstChild.scrollWidth;
	}

	move() {
		const parentElement = this.messagesBar2.nativeElement;
		const firstChild    = parentElement.children[0];
		var   messageWidth  = firstChild.scrollWidth;
		var   speed 		= 10.1 - this.settings.MESSAGES_SPEED;
		var   timing        = (messageWidth / 100) * (speed * 1000);

		firstChild.animate(
			[
				{marginLeft: "0px"},
				{marginLeft: -Math.abs(messageWidth) + "px"}
			],
			{
				duration: timing,
			}
		)

		setTimeout(() => {
			this.messages.push(this.messages[0]);
			this.messages.splice(0, 1);
			var messageId = parseInt(firstChild.getAttribute("data-message-id"));
			var message_buffer = this.messages_buffer.filter(obj => { return obj['id'] == messageId});
			var message_ids = [];
			for(let i = 0; i < this.messages.length; i++) {
				message_ids.push(this.messages[i]['id']);
			}

			this.compareLength();
			if(message_buffer.length == 0) {
				var message_new = null;
				var message_pos = message_ids.indexOf(messageId);

				for(let i = 0; i < this.messages_buffer.length; i++) {
					if(message_ids.indexOf(this.messages_buffer[i]['id']) == -1) {
						message_new = this.messages_buffer[i];
					}
				}
				
				if(message_new !== null) {
					this.messages[message_pos] = message_new;
				}
			}

			setTimeout(() => {
				this.move();
			}, 1)
		}, timing);
	}

	compareLength() {
		if(this.messages_buffer.length > this.messages.length) {
			var message_ids = [];
			for(let i = 0; i < this.messages.length; i++) {
				message_ids.push(this.messages[i]['id']);
			}
			for(let i = 0; i < this.messages_buffer.length; i++) {
				if(message_ids.indexOf(this.messages_buffer[i]['id']) == -1) {
					this.messages.push(this.messages_buffer[i]);
				}
			}
		}
		if(this.messages_buffer.length < this.messages.length) {
			var buffer_ids = [];
			for(let i = 0; i < this.messages_buffer.length; i++) {
				buffer_ids.push(this.messages_buffer[i]['id']);
			}
			for(let i = 0; i < this.messages.length; i++) {
				if(buffer_ids.indexOf(this.messages[i]['id']) == -1) {
					this.messages.splice(i, 1);
				}
			}
		}
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
	}

}
