import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { StatusService } from 'src/app/services/status/status.service';

import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { AuthenticationService, QuizRoles, TOKEN_NAME } from 'src/app/services/authentication/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSidenav } from '@angular/material/sidenav';

const SESSION_STORAGE_KEY: string = "quizId";

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

	connectedUsers : number  = 0;
	isSideNavOpen  : boolean = false;
	sideNavMode    : string  = "side"
	allQuizzes     : object[];
	subMenuOpen	   : string;
	isLoading	   : boolean = true;
	roles          = QuizRoles;
	sideNav        : MatSidenav;
	
	constructor(
		private api     : ApiService,
		private quiz    : StatusService,
		private quiz2   : QuizService,
		private snackbar: MatSnackBar,
		private router  : Router,
		public  socket  : WebSocketService,
		public  user    : AuthenticationService
	) { }
	
	async ngOnInit() {
		this.sideNavSettings();
		await this.getAllQuizzes();
		await this.getActiveQuiz();
		this.isLoading = false;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.sideNavSettings();
	}
	
	sideNavSettings() {
		this.isSideNavOpen = window.innerWidth > 768 ? true : false;
		this.sideNavMode   = window.innerWidth > 768 ? "side" : "over";
	}

	closeSideNav() {
		console.log(this.sideNavMode);
		if(this.sideNavMode == "over") {
			console.log("close..");
			this.sideNav.close();
			console.log(this.isSideNavOpen);
		}
	}
	getAllQuizzes(): Promise<void> {
		return new Promise(async (res, rej) => {
			let result = await <Promise<object[]>>this.api.request("get", "participation/admin");
			if(result['data']) {
				this.allQuizzes = result['data'];
				this.allQuizzes.sort((a, b) => (a['quiz']['id'] > b['quiz']['id']) ? -1 : 1)
				if(this.allQuizzes.length === 0	) {
					this.snackbar.open('Je hebt geen rechten om quizzen te beheren');
				}
				else if(!sessionStorage.getItem(SESSION_STORAGE_KEY)) {
					sessionStorage.setItem(SESSION_STORAGE_KEY, this.allQuizzes[0]['quiz']['id']);
				}
				this.quiz.get();
				res();
			} else {
				this.snackbar.open('Kon quizzen niet ophalen');
			}
		});
	}

	getActiveQuiz(): Promise<void> {
		return new Promise(async (res, rej) => {
			await this.quiz.get();
			await this.quiz2.getQuiz();
			await this.socket.connect(this.quiz2.url)
			res();
		});
	}
	setActiveQuiz(id) {
		sessionStorage.setItem(SESSION_STORAGE_KEY, id);
		this.getActiveQuiz();
	}
	
	logout(): void {
		localStorage.removeItem(TOKEN_NAME);
		this.router.navigateByUrl("/admin/login");
	}
}
