import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    

@Injectable({    
	providedIn: 'root'    
})    
export class EventEmitterService {    
	
	invokeFirstComponentFunction;    
	subsVar: Subscription;    
	
	constructor() { }    
	
	startListening() {
		this.invokeFirstComponentFunction = new EventEmitter();  

	}

	doFunction() {    
		this.invokeFirstComponentFunction.emit();    
	}    
}  