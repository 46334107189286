<mat-card class="create-quiz__wrapper" *ngIf="!isLoading">
    <mat-horizontal-stepper [linear]="true" #stepper>
        <ng-template matStepperIcon="edit">
            <mat-icon>check</mat-icon>
        </ng-template>
        <form [formGroup]="quizFormGroup">
        <mat-step [formGroup]="quizFormGroup">
            <ng-template matStepLabel>Quiz</ng-template>
            <div class='create-quiz__form-wrapper'>
                <h2 class='create-quiz__title'>Quiz</h2>
                <mat-form-field class='create-quiz__input'>
                    <mat-label>Quiz naam</mat-label>
                    <input matInput formControlName="name" placeholder="Quiz naam">
                </mat-form-field><br>
                
                <mat-form-field class='create-quiz__input create-quiz__date'>
                    <mat-label>Quiz datum</mat-label>
                    <input matInput formControlName="date" placeholder="Quiz datum" [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class='create-quiz__hour'>
                    <mat-label>Uur</mat-label>
                    <mat-select [value]="time['hour']" (selectionChange)="changeTime('hour', $event)">
                        <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class='create-quiz__minute'>
                    <mat-label>Minuut</mat-label>
                    <mat-select [value]="time['minute']" (selectionChange)="changeTime('minute', $event)">
                        <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class='create-quiz__input create-quiz__date'>
                    <mat-label>Automatisch openen</mat-label>
                    <input matInput [value]="quiz['openAt']" placeholder="Automatisch openen" [matDatepicker]="openAtPicker"(dateChange)="setDateTest($event)">
                    <mat-datepicker-toggle matSuffix [for]="openAtPicker"></mat-datepicker-toggle>
                    <mat-datepicker #openAtPicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class='create-quiz__hour'>
                    <mat-label>Uur</mat-label>
                    <mat-select [value]="openAtTime['hour']" (selectionChange)="changeOpenTime('hour', $event)">
                        <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class='create-quiz__minute'>
                    <mat-label>Minuut</mat-label>
                    <mat-select [value]="openAtTime['minute']" (selectionChange)="changeOpenTime('minute', $event)">
                        <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <br>
                <mat-form-field class='create-quiz__input'>
                    <mat-label>Quiz URL</mat-label>
                    <input matInput formControlName="url" placeholder="Quiz URL">
                </mat-form-field>
                <!-- <div class="domain-input__wrapper"> -->
                    <!-- <mat-form-field class='create-quiz__input'>
                        <mat-label>Domein</mat-label>
                        <input matInput formControlName="domain" placeholder="Quiz URL">
                    </mat-form-field>
                    <span class="domain-input__slash">/</span> -->
                <!-- <mat-form-field class='create-quiz__input'>
                    <mat-label>Quiz URL</mat-label>
                    <input matInput formControlName="identifier" placeholder="Quiz URL">
                </mat-form-field> -->
                <!-- </div> -->
                <mat-form-field class='create-quiz__input'>
                    <mat-label>Youtube URL</mat-label>
                    <input matInput formControlName="youtube" placeholder="YouTube URL">
                </mat-form-field>
                <button 
                    mat-flat-button color="primary" 
                    matStepperNext 
                    style="display: block; margin-top: 10px;"
                    [disabled]="
                        quizFormGroup.get('name').invalid ||
                        quizFormGroup.get('date').invalid ||
                        quizFormGroup.get('url').invalid
                    "
                >Volgende</button>
            </div>
        </mat-step>
        <mat-step>
        <ng-template matStepLabel>Rondes</ng-template>
            <div class='create-quiz__form-wrapper'>
                <h2>Rondes</h2>
                <div class='rounds__wrapper' cdkDropList (cdkDropListDropped)="drop($event)">
                    <div class='round-input__wrapper mat-elevation-z2' cdkDrag formArrayName="rounds" *ngFor="let round of getControls(quizFormGroup, 'rounds'); let i = index">
                        <div [formGroupName]="i">
                            <div class="round-input__handle" cdkDragHandle>
                                <span class="material-icons">
                                    drag_indicator
                                </span>
                            </div>
                            <input type='hidden' formControlName='order'>
                            <mat-form-field class='round-input__title   '>
                                <mat-label>Titel</mat-label>
                                <input matInput formControlName='title'>
                            </mat-form-field>
                            <mat-form-field class='round-input__questions'>
                                <mat-label>Vragen</mat-label>
                                <input matInput type='number' min="1" pattern="[0-9]*"  formControlName='questions'>
                            </mat-form-field>
                            <button mat-icon-button class='round-input__delete' (click)="deleteRound(i)" *ngIf="getControls(quizFormGroup,'rounds').length > 1">
                                <span class="material-icons">
                                    delete
                                </span> 
                            </button>
                        </div>
                    </div>
                </div>
                <button mat-flat-button class='round-input__add' (click)='addRound()'>Ronde toevoegen</button>
                <button mat-stroked-button color="primary" matStepperPrevious style="margin-right: 10px">Terug</button>
                <button mat-flat-button color="primary" matStepperNext [disabled]="quizFormGroup.get('rounds').invalid">Volgende</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Teksten</ng-template>
            <div class='create-quiz__form-wrapper'>
                <div formGroupName="texts">
                    <h2>Teksten</h2>
                    <h3>Offline</h3>
                    <div formGroupName="offline">
                        <mat-form-field class='create-quiz__input'>
                            <mat-label>Offline titel</mat-label>
                            <input matInput formControlName="title" placeholder="Offline titel" (input)="editTitle($event)">
                        </mat-form-field><br>
                        <mat-form-field class='create-quiz__input'>
                            <mat-label>Offline tekst</mat-label>
                            <input matInput formControlName="text" placeholder="Offline tekst">
                        </mat-form-field><br>
                        <mat-form-field class='create-quiz__input'>
                            <mat-label>Offline viltje</mat-label>
                            <input matInput formControlName="viltje" placeholder="Offline viltje" (input)="editViltje($event)">
                        </mat-form-field><br>
                    </div>

                    <h3>Ready</h3>
                    <div formGroupName="ready">
                        <mat-form-field class='create-quiz__input'>
                            <mat-label>Ready titel</mat-label>
                            <input matInput formControlName="title" placeholder="Ready titel" (input)="editTitle($event)">
                        </mat-form-field><br>
                        <mat-form-field class='create-quiz__input'>
                            <mat-label>Ready tekst</mat-label>
                            <input matInput formControlName="text" placeholder="Ready tekst">
                        </mat-form-field><br>
                        <mat-form-field class='create-quiz__input'>
                            <mat-label>Ready viltje</mat-label>
                            <input matInput formControlName="viltje" placeholder="Ready viltje" (input)="editViltje($event)">
                        </mat-form-field><br>
                    </div>

                    <h3>Eind</h3>
                    <div formGroupName="end">
                        <mat-form-field class='create-quiz__input'>
                            <mat-label>Eind titel</mat-label>
                            <input matInput formControlName="title" placeholder="Eind titel" (input)="editTitle($event)">
                        </mat-form-field><br>
                        <mat-form-field class='create-quiz__input'>
                            <mat-label>Eind tekst</mat-label>
                            <input matInput formControlName="text" placeholder="Eind tekst">
                        </mat-form-field><br>
                        <mat-form-field class='create-quiz__input'>
                            <mat-label>Eind viltje</mat-label>
                            <input matInput formControlName="viltje" placeholder="Eind viltje" (input)="editViltje($event)">
                        </mat-form-field><br>
                    </div>
                    <button mat-stroked-button color="primary" matStepperPrevious style="margin-right: 10px">Terug</button>
                    <button mat-flat-button color="primary" matStepperNext (click)="saveQuiz()" [disabled]="quizFormGroup.invalid">Opslaan</button>
                </div>
            </div>
        </mat-step>
    </form>
    </mat-horizontal-stepper>
</mat-card>