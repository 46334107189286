<div class="messages-form__wrapper">
    <mat-card class="messages-form__form">
        <!-- <mat-card-header> -->
            <mat-card-title>Berichtje</mat-card-title>
            <mat-card-subtitle>Stuur een bericht naar de studio</mat-card-subtitle>
        <!-- </mat-card-header> -->
        <ng-container *ngIf="!done">
            <mat-card-content>
                <mat-form-field class="messages-form__text-input">
                    <mat-label>Bericht</mat-label>
                    <textarea matInput
                            [(ngModel)]="message"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            maxlength="140"
                            [disabled]="isLoading"
                            cdkAutosizeMaxRows="5"></textarea>
                    </mat-form-field>
                    <div class="messages-form__input-length"
                        [class.messages-form__input-length--warn]="message.length > 100 && message.length < 140"
                        [class.messages-form__input-length--danger]="message.length == 140"
                >
                    <span class="messages-form__input-length-current">{{message.length}}</span>
                    <span class="messages-form__input-length-devider">/</span>
                    <span class="messages-form__input-length-max">140</span>
                </div>
            </mat-card-content>
            <mat-card-actions align="end">
                <a mat-button 
                style="margin-right: auto"
                (click)="setHide()"
                [disabled]="isLoading"
                >
                    Annuleren
                </a>
                <button mat-flat-button 
                        color="primary"
                        [disabled]="message.length < 4 || isLoading"
                        (click)="submitMessage()"
                >
                    Versturen
                </button>
            </mat-card-actions>
        </ng-container>
        <ng-container *ngIf="done">
            <div class="message-form__done">
                Je bericht is verzonden.
            </div>
        </ng-container>
    </mat-card>
</div>