import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';

@Component({
	selector: 'app-messages-form',
	templateUrl: './messages-form.component.html',
	styleUrls: ['./messages-form.component.scss']
})
export class MessagesFormComponent implements OnInit {
	
	message  : string  = "";
	isLoading: boolean = false;
	done	 : boolean = false;
	
	constructor(
		private api   : ApiService,
		private quiz  : QuizService,
		private socket: WebSocketService
	) { }
	
	ngOnInit(): void {
	}

	async submitMessage() {
		try {
			var result = await this.api.request("post", "messages/"+this.quiz.id, {body: {message: this.message}});
			if(!result['errors']) {
				this.socket.emit("messages_updated", {});
				this.done = true;
				setTimeout(() => {
					this.setHide();
					setTimeout(() => {
						this.done = false;
					}, 700);
				}, 1500);
			}
		} catch {
			// error..
		}
	}

    @Output() onHide = new EventEmitter<boolean>();
    setHide(){
       this.onHide.emit(false);
    }
}
