import { Component, OnInit } from '@angular/core';
import { StatusService } from 'src/app/services/status/status.service';
import { ApiService } from 'src/app/services/api/api.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-round-overview-teams',
	templateUrl: './round-overview-teams.component.html',
	styleUrls: ['./round-overview-teams.component.scss']
})
export class RoundOverviewTeamsComponent implements OnInit {
	
	users: object = {};
	displayedColumns: string[] = ["status", "name", "points"];
	
	constructor(
		public  quiz    : StatusService,
		public  api     : ApiService,
		public  spinner : SpinnerService,
		private snackbar: MatSnackBar
	) { }
	
	ngOnInit(): void {
		this.spinner.start(false);
		this.setUsers();
		this.spinner.stop();
	}

	setUsers() {
		for(let i = 0; i < this.quiz.rounds.length; i++) {
			let id    = this.quiz.rounds[i]['id'];
			let title = this.quiz.rounds[i]['title']
			this.users[id + "_" + title] = [];
		}
	}
	
	async getUsers(id, title) {
		if(this.users[id + "_" + title].length == 0) {
			try {
				this.users[id + "_" + title] = await <Promise<object[]>>this.api.request("get", "user/round/"+id);
			} catch(err) {
				this.snackbar.open(err);
			}
		}
	}
	
}
	