import {AbstractControl, ValidatorFn} from '@angular/forms';

export function validatorMatchWith(controlName: string): ValidatorFn {
    return (control:AbstractControl) => {
        const group = control.parent;
        if(group) {
            var fieldOneValue = control.value;
            var fieldTwoValue = group.controls[controlName].value;
            
            if(fieldOneValue == fieldTwoValue) {
                return null;
            } else {
                return {'validatorMatchWith': true };
            }
        }
        return {'validatorMatchWith': true };
    }
}