import { Injectable } from '@angular/core';
import io from 'socket.io/node_modules/socket.io-client';
import { environment } from 'src/environments/environment';
import { Observable, TimeInterval } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SyncDialogComponent } from 'src/app/components/helpers/dialogs/sync-dialog/sync-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class WebSocketService {

	socket        : any;
	timeout       : number = 0;
	serverSyncTime: number = 0;
	room          : string = "";
	tries         : number = 0;
	interval;
	
	constructor(
		private dialog: MatDialog,
	) { }
	
	async connect(room) {
		this.room = room;
		// TODO: user id meesturen naar de websocket zodat die aan het socket object toegevoegd kan worden
		if(environment.production) {
			this.socket = await io(environment.SOCKET_URL);
			// this.socket = await io(environment.SOCKET_URL, {secure: true});
			// await this.socket.emit('join', room);
		} else {
			this.socket = await io(environment.SOCKET_URL);
			// await this.socket.emit('join', room);
		}

		this.socket.on('connect', async (data) => {
			// console.log("Join room " + room);
			await this.socket.emit('join', room);
		});

		// clearInterval(this.interval);
		// this.interval = setInterval(() => { this.checkStatus() }, 5000);
		// this.onDisconnect();
	}

	// async checkStatus() {
	// 	console.log(this.socket.connected);
	// 	if (! this.socket.connected) {
	// 		this.tries++;
	// 	}
	// }

	// async onDisconnect() {
	// 	this.socket.on('disconnect', () => {
	// 		console.log("Socket is disconnected.. :(");
	// 	});
	// }

	listener(eventName: string) {

		var thisService = this;
		return new Observable((Subscriber) => {
			thisService.socket.on(eventName, (data) => {

				if(
					eventName == "open sync dialog" || 
					eventName == "close sync dialog" || 
					eventName == "clear sync" || 
					eventName == "set serverSyncTime"
				) {
					this.timeout = 0;
				}

				setTimeout(() => {
					Subscriber.next(data);
				}, this.timeout);
			})
		});
	}
	
	emit(eventName: string, data: object) {
		data['quizId'] = sessionStorage.getItem("quizId");
		this.socket.emit(eventName, data);
	}

	openSyncDialog() {
		this.dialog.open(SyncDialogComponent,{
			disableClose: true
		});
	}
	
	closeSyncDialog() {
		this.dialog.closeAll();
	}

	sync() {
		var now = new Date();
		var milliseconds = now.getTime();
		if(this.serverSyncTime > 0) {
			this.timeout = milliseconds - this.serverSyncTime;
			this.serverSyncTime = 0;
		}
	}
}
