import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

	counter  : number = 0;
	timeout  : any;
	isLoading: boolean = false;

  	constructor() { }

	start(withTimeout:boolean = true) {
    var thisComponent = this;
		if(this.counter <= 0) {
			if(withTimeout) {
				this.timeout = setTimeout(function() {
          			thisComponent.isLoading = true;
				}, 500);
			} else {
				this.isLoading = true;
			}
		}
		this.counter++;
	}

	stop() {
		this.counter--;
		if(this.counter == 0) {
      		clearTimeout(this.timeout);
			this.isLoading = false;
		}
	}
}
