import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { ApiService } from 'src/app/services/api/api.service';
import { StatusService } from 'src/app/services/status/status.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ReviewMoreInfoComponent } from '../review-more-info/review-more-info.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-review-team',
	templateUrl: './review-team.component.html',
	styleUrls: ['./review-team.component.scss']
})
export class ReviewTeamComponent implements OnInit, OnDestroy {
	
	answers         : object[];
	device          : string;
	displayedColumns: string[] 	= ["number", "answer", "action-correct", "action-incorrect"];
	user            : object;
	round           : object;
	totalPoints     : number;
	isLoading       : boolean 	= true;
	mouseTimeout;
	private unsubscribe = new Subject;
	ReviewMoreInfoDialog: MatDialogRef<ReviewMoreInfoComponent>;
	
	constructor(
		private activatedRoute: ActivatedRoute,
		private api           : ApiService,
		private router		  : Router,
		public  spinner       : SpinnerService,
		public  quiz          : StatusService,
		private dialog        : MatDialog,
	) { }
	
	ngOnInit(): void {
		this.watchUrl();
		if(window.innerWidth > 768) {
			this.displayedColumns.push('action-more');
			this.device = "desktop";
		} else {
			this.device = "mobile";
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if(window.innerWidth > 768) {
			if(this.displayedColumns.indexOf('action-more') == -1) {
				this.displayedColumns.push('action-more');
			}
			this.device = "desktop";
		} else {
			let pos = this.displayedColumns.indexOf('action-more');
			if(pos > -1) {
				this.displayedColumns.splice(pos, 1);
			}
			this.device = "mobile";
		}
	}
	
	watchUrl() {
		this.activatedRoute.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(async (params) => {
			this.isLoading = true;
			this.getRound(params.get("round"));
			await this.getUser(params.get("user"));
			await this.getAnswers();
			this.setTotalPoints();
			this.isLoading = false;
		});
	}

	getRound(roundId) {
		if(!this.round || this.round['id'] != roundId) {
			this.round = this.quiz.rounds.filter(rounds => { return rounds['id'] == roundId })[0];
		}
	}

	getAnswers() {
		return new Promise(async (res, rej) => {
			this.answers = await <Promise<object[]>>this.api.request("get", "answers/user/"+this.user['id']+"/"+this.round['id']);
			res(true);
		});
	}
	
	async getUser(userId) {
		return new Promise(async (res, rej) => {
			if(!this.user || this.user['id'] != userId) {
				let result = await this.api.request("get", "user/"+userId);
				this.user = result['data'];
				res(true)
			}
		});
	}
	
	reviewEverything(points) {
		for(let i = 0; i < this.answers.length; i++) {
			this.answers[i]['points'] = points;
		}
		this.setTotalPoints();
	}

	openDialog(row):void {
		this.ReviewMoreInfoDialog = this.dialog.open(ReviewMoreInfoComponent, {
			data: {
				round : this.round['id'],
				number: row['number'],
				points: row['points'],
				answer: row['answer'],
			}
		});
		
		this.ReviewMoreInfoDialog.afterClosed().pipe(
			filter(points => points)
		).subscribe(points => {
			row.points = parseFloat(points);
		});
		this.setTotalPoints();
	}
	
	reviewQuestion(question, points) {
		var answers = this.answers.filter(obj => {
			return obj['number'] == question;
		});

		// in the weird case there are more answers for the same question, we loop through all the results
		for(let i = 0; i < answers.length; i++) {
			answers[i]['points'] = points;
		}
		this.setTotalPoints();
	}

	setTotalPoints() {
		this.totalPoints = 0;
		for(let i = 0; i < this.answers.length; i++) {
			this.totalPoints += this.answers[i]['points'];
		}
	}

	async savePoints(action) {
		await this.api.request("put", "answers/"+this.round['id']+"/", 
		{
			"body": {
				"method": "1",
				"answers": this.answers,
				"user": this.user['id']
			}
		});
		if(action == "overview") {
			this.router.navigateByUrl("/admin/nakijken/teams");
		}
		return true;
	}
	
	longPress(row) {
		if(this.device == 'mobile') {
			this.mouseTimeout = setTimeout(() => {
				this.openDialog(row);
			}, 750);
		}
	}

	mouseUp() {
		clearTimeout(this.mouseTimeout);
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
	
}
