import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { EventEmitterService } from '../../../../services/event-emitter/event-emitter.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-form-dialog',
	templateUrl: './form-dialog.component.html',
	styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent implements OnInit {
	
	title               : string = "Let op!"
	form                : FormGroup;
	text                : string = "";
	doneText            : string = "";
	doneBtnText         : string = "";
	doneBtnLink         : string = "";
	hintUrl             : string = "";
	hintText            : string = "";
	formFields          : string[];
	submitFunction;
	errorMsg            : string;
	buttonText          : string  = "Ja";
	sent                : boolean = false;
	customCss           : string = "";
	links				: object = { 
		text: "", 
		url: ""
	}
	private watcher     : Subscription = new Subscription();
	
	ngOnInit() {}

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogRef: MatDialogRef<FormDialogComponent>,
		private eventEmitterService: EventEmitterService,
	) {
		
		dialogRef.disableClose = true;
		
		if (data) {
			this.title          = data.title || this.title;
			this.text           = data.text || this.text;
			this.doneText       = data.doneText || this.doneText;
			this.doneBtnText    = data.doneBtnText || this.doneBtnText;
			this.doneBtnLink    = data.doneBtnLink || this.doneBtnLink;
			this.hintUrl        = data.hintUrl || this.hintUrl;
			this.hintText       = data.hintText || this.hintText;
			this.form           = data.formGroup;
			this.formFields     = data.formFields;
			this.buttonText     = data.buttonText || this.buttonText;
			this.submitFunction = data.submit;
			this.links			= data.links || this.links;
			this.customCss  	= data.customCss || this.customCss;
		}
		this.dialogRef.updateSize('300vw','300vw')
	}
	
	formName(field) {
		return Object.keys(field)[0];
	}
	
	submitForm() {
		this.eventEmitterService.doFunction();  
	}
}
