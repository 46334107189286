import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { QuizService } from '../quiz/quiz.service';
import { Subject } from 'rxjs';
import { WebSocketService } from '../web-socket/web-socket.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class SettingService {
	
	MESSAGES_ENABLED       : boolean = true;
	MESSAGES_AMOUNT        : number = 5;
	MESSAGES_SPEED         : number = 8.5;
	MESSAGES_EMPTY_MESSAGE : string = 'Jouw berichtje hier? Klik op \'Bericht sturen\' in het menu van het antwoordformulier';
	
	private onDestroy$       : Subject<void> = new Subject<void>();
	
	constructor(
		private api   : ApiService,
		private quiz  : QuizService,
		private socket: WebSocketService
	) { }

	listen() {
		this.socket
			.listener("update_settings")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(async (data) => {
				await this.fetchSettings();
			})
		;
	}

	fetchSettings() {
		return new Promise(async (res, rej) => {
			try {
				let settings = await <Promise<object[]>>this.api.request('get', 'settings/'+this.quiz.id);

				this.MESSAGES_AMOUNT        = <number>settings['data']['MESSAGES_AMOUNT'] || this.MESSAGES_AMOUNT;
				this.MESSAGES_SPEED         = <number>settings['data']['MESSAGES_SPEED'] || this.MESSAGES_SPEED;
				this.MESSAGES_EMPTY_MESSAGE = <string>settings['data']['MESSAGES_EMPTY_MESSAGE'] || this.MESSAGES_EMPTY_MESSAGE;
				if(settings['data']['MESSAGES_ENABLED'] !== undefined) {
					this.MESSAGES_ENABLED       = settings['data']['MESSAGES_ENABLED'] == "1" ? true : false;
				}

				res(settings);
			} catch(err) {
				// default error handling
			}
		});
	}

	fetchSetting(key) {
		return new Promise(async (res, rej) => {
			try {
				this[key] = await <object>this.api.request('get', 'settings/'+this.quiz.id+'/'+key, {ignoreErrors: false});
				res(this[key]);
			} catch(err) {
				res(this[key]);
			}
		});
	}

	all() {
		return {
			'MESSAGES_ENABLED'       : this.MESSAGES_ENABLED,
			'MESSAGES_AMOUNT'        : this.MESSAGES_AMOUNT,
			'MESSAGES_SPEED'         : this.MESSAGES_SPEED,
			'MESSAGES_EMPTY_MESSAGE' : this.MESSAGES_EMPTY_MESSAGE,
		};
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
	}
}
