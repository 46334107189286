import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { MatSnackBar } from '@angular/material/snack-bar';
import { WebSocketService } from '../web-socket/web-socket.service';
import { QuizService } from '../quiz/quiz.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

const jwt = new JwtHelperService();

export const TOKEN_NAME			: string = 'jwt_token';
export const REFRESH_TOKEN_NAME : string = 'jwt_refresh_token';
export enum QuizRoles {
	Admin = 100,
	Moderator = 80,
	Reviewer = 60,
	User = 10
}

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	
	private headers = new HttpHeaders({'Content-Type': 'application/json'});
	private onDestroy$       : Subject<void> = new Subject<void>();

	constructor(
		private http: HttpClient,
		private snackbar: MatSnackBar,
		private socket: WebSocketService,
		private quiz: QuizService,
		private api: ApiService,
	) { }
	
	getToken(): string {
		return localStorage.getItem(TOKEN_NAME);
	}
	
	setToken(token: string): void {
		localStorage.setItem(TOKEN_NAME, token);
		let decoded = jwt.decodeToken(token);
		localStorage.setItem("name", decoded.name);
	}

	removeToken(): void {
		localStorage.removeItem(TOKEN_NAME);
		localStorage.removeItem("name");
	}
	
	getRefreshToken(): string {
		return localStorage.getItem(REFRESH_TOKEN_NAME);
	}
	
	setRefreshToken(token: string): void {
		localStorage.setItem(REFRESH_TOKEN_NAME, token);
	}

	removeRefreshToken(): void {
		localStorage.removeItem(REFRESH_TOKEN_NAME);
	}

	getTokenExpirationDate(token: string): Date {
		const decoded = jwt.decodeToken(token);
		
		if (decoded.exp === undefined) return null;
		
		const date = new Date(0); 
		date.setUTCSeconds(decoded.exp);
		return date;
	}
	
	isTokenExpired(token?: string): boolean {
		if(!token) token = this.getToken();
		if(!token) return true;
		
		const date = this.getTokenExpirationDate(token);
		if(date === undefined) return false;
		return !(date.valueOf() > new Date().valueOf());
	}
	
	getRole():string {
		const decoded = jwt.decodeToken(this.getToken());
		
		if (decoded.app_role === undefined) return null;
		
		return decoded.app_role;
	}
	
	getQuizRole():QuizRoles {
		const decoded = jwt.decodeToken(this.getToken());
		
		if (decoded.quiz_roles === undefined) return null;
		let UserQuizRole = decoded.quiz_roles[this.quiz.getId()];


		// TODO: Hier moet een betere manier voor zijn..
		switch (UserQuizRole) {
			case 'Admin':
				return QuizRoles.Admin;
				break;
			case 'Moderator':
				return QuizRoles.Moderator;
				break;
			case 'Reviewer':
				return QuizRoles.Reviewer;
				break;
			case 'user':
			default:
				return QuizRoles.User;
				break;
		}
	}

	hasRights(role: QuizRoles):boolean {
		let userQuizRole = this.getQuizRole();
		return userQuizRole >= role;
	}

	//depricated
	isQuizAdmin():boolean {
		const decoded = jwt.decodeToken(this.getToken());

		for(let quiz in decoded.quiz_roles) {
			let role = decoded.quiz_roles[quiz];
			if(role.toLowerCase() !== 'user') {
				return true;
			} 
		}
		return false;
	}
	
	login(user, pass):Promise<string>{
		return new Promise((resolve, rej) => {
			let body = {name: user, password: pass};
			if(sessionStorage.getItem("quizId")) {
				body['quiz'] = sessionStorage.getItem("quizId");
			}
			if(this.socket.socket && this.socket.socket.id) {
				body['socketId'] = this.socket.socket.id;
			}
			this.http
				.post(environment.API_URL + 'user/login', body, { headers: this.headers })
				.toPromise()
				.then(res => {
					this.setToken(res['token']);
					this.setRefreshToken(res['refresh']);
					// let decodedToken = jwt.decodeToken(localStorage.getItem(TOKEN_NAME));
					// let id = decodedToken.id;
					// this.socket.emit("user login", {id:id})
					this.listenForRefresh();
					resolve(res['token']);
				})
				.catch(err => {
					// this.removeToken();
					var msg = err.error ? err.error.message : err.message;
					this.snackbar.open(msg, 'Sluiten', {duration: 3000});
			});
		});
	}
	
	signup(user, pass, email):Promise<string>{
		return new Promise((resolve, rej) => {
			let body = {name: user, password: pass, email: email};
			if(sessionStorage.getItem("quizId")) {
				body['quiz'] = sessionStorage.getItem("quizId");
			}
			this.http
			.post(environment.API_URL + 'user/signup', body, { headers: this.headers })
			.toPromise()
			.then(res => {
				this.setToken(res['token']);
				this.setRefreshToken(res['refresh']);
				let decodedToken = jwt.decodeToken(localStorage.getItem(TOKEN_NAME));
				let id = decodedToken.id;
				this.socket.emit("user login", {id:id})
				this.listenForRefresh();
				resolve(res['token']);
			})
			.catch(err => {
				this.removeToken();
				this.removeRefreshToken();
				this.snackbar.open(err.error.message, 'Sluiten', {duration: 3000});
			});
		});
	}

	listenForRefresh() {
		this.socket
			.listener("refresh_token")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(async (data) => {
				await this.refreshToken();
			}
		);
	}

	async refreshToken() {
		let result = await this.api.request("post", "user/refresh_token", {body: {refreshToken: this.getRefreshToken()}});
		let token = result['data']['token'];
		this.setToken(token);
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
	}
}