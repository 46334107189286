<div 
    #messagesBar
    class="messages-bar__wrapper"
    [class.messages-bar--disabled]="!settings.MESSAGES_ENABLED"
    *ngIf="!isLoading"
>
    <ng-container *ngFor="let message of messages;let i = index">
        <div 
            class="messages-bar__message"
            [attr.data-message-id]="message.id"
            [class.messages-bar__120vw]="message.id == 0"
        >
            <span class="messages-bar__name">{{ message.user.name }}</span>
            <span class="messages-bar__colon" *ngIf="message.id != 0"> : </span>
            <span class="messages-bar__body">{{ message.message }}</span>
            <span class="messages-bar__devider"> - </span>
        </div>
    </ng-container>
</div>