import { Component, OnInit } from '@angular/core';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';

@Component({
	selector: 'app-game',
	templateUrl: './game.component.html',
	styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {
	
	constructor(
		public quiz: QuizService,
		public socket: WebSocketService
	) { }
	
	ngOnInit(): void {
	}
}
