<div class="messages__settings">
    <mat-card class="messages__settings-card">
        <mat-card-header>
            <div mat-card-avatar>
                <span class="material-icons">done</span>
            </div>
            <mat-card-title>Inschakelen</mat-card-title>
            <mat-card-subtitle>Berichten toestaan?</mat-card-subtitle>
            <div class="messages__settings-control">
                <mat-slide-toggle 
                    [checked]="enabled"
                    [disabled]="enabled_loading"
                    (change)="updateEnabled($event)"
                ></mat-slide-toggle>
            </div>
        </mat-card-header>
    </mat-card>
    <mat-card class="messages__settings-card">
        <mat-card-header>
            <div mat-card-avatar>
                <span class="material-icons">speed</span>
            </div>
            <mat-card-title>Snelheid</mat-card-title>
            <mat-card-subtitle>Snelheid balk</mat-card-subtitle>
            <div class="messages__settings-control">
                <mat-slider
                    thumbLabel
                    [displayWith]="formatLabel"
                    tickInterval="1"
                    min="1"
                    max="100"
                    [disabled]="speed_loading"
                    [value]="speed"
                    (change)="updateSpeed($event)"
                >
                </mat-slider>
            </div>
        </mat-card-header>
    </mat-card>
    <mat-card class="messages__settings-card">
        <mat-card-header>
            <div mat-card-avatar>
                <span class="material-icons">chat_bubble_outline</span>
            </div>
            <mat-card-title>Aantal</mat-card-title>
            <mat-card-subtitle>Aantal berichten</mat-card-subtitle>
            <div class="messages__settings-control">
                <button 
                    mat-icon-button
                    [disabled]='amount_loading' 
                    (click)="updateAmount('minus')"
                >
                    <span class="material-icons">
                        remove
                    </span>
                </button>
                <span class="messages__settings-amount">{{amount}}</span>
                <button 
                    mat-icon-button
                    [disabled]='amount_loading' 
                    (click)="updateAmount('plus')"
                >
                <span class="material-icons">
                    add
                </span>
                </button>
            </div>
        </mat-card-header>
    </mat-card>
</div>
<mat-card>
    <mat-card-title>Berichten</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
    <mat-card-content>
        <mat-tab-group animationDuration="0ms" *ngIf="!isLoading">
            <mat-tab label="Nieuw"> 
                
                <table mat-table [dataSource]="getMessageWithStatus(null)" class="full-width mat-table-alternate mat-elevation-z0">
                    <ng-container matColumnDef="name">
                        <th class="messages__name" mat-header-cell *matHeaderCellDef> Naam </th>
                        <td class="messages__name" mat-cell *matCellDef="let element">{{ element.user.name }} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef class='messages__body'> Bericht </th>
                        <td mat-cell *matCellDef="let element" class='messages__body'> {{ element.message }} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="messages__action"> Actie </th>
                        <td mat-cell *matCellDef="let element" class="messages__action"> 
                            <button mat-mini-fab (click)="reviewMessage(element.id, true)" class='mat-elevation-z1 messages__button messages__button--approve'>
                                <span class="material-icons">done</span>
                            </button>
                            <div class="messages__spacer"></div>
                            <button mat-mini-fab color="warn" (click)="reviewMessage(element.id, false)" class='mat-elevation-z1 messages__button messages__button--disapprove'>
                                <span class="material-icons">clear</span>
                            </button>
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                </table>

            </mat-tab>
            <mat-tab label="Goedgekeurd"> 

                <table mat-table [dataSource]="getMessageWithStatus(true)" class="full-width mat-table-alternate mat-elevation-z0">
                    <ng-container matColumnDef="name">
                        <th class="messages__name" mat-header-cell *matHeaderCellDef> Naam </th>
                        <td class="messages__name" mat-cell *matCellDef="let element">{{ element.user.name }} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef class='messages__body'> Bericht </th>
                        <td mat-cell *matCellDef="let element" class='messages__body'> {{ element.message }} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="messages__action"> Actie </th>
                        <td mat-cell *matCellDef="let element" class="messages__action"> 
                            <button mat-mini-fab color="warn" (click)="reviewMessage(element.id, false)" class='mat-elevation-z1 messages__button messages__button--disapprove'>
                                <span class="material-icons">clear</span>
                            </button>
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                </table>

            </mat-tab>
            <mat-tab label="Afgekeurd">

                <table mat-table [dataSource]="getMessageWithStatus(false)" class="full-width mat-table-alternate mat-elevation-z0">
                    <ng-container matColumnDef="name">
                        <th class="messages__name" mat-header-cell *matHeaderCellDef> Naam </th>
                        <td class="messages__name" mat-cell *matCellDef="let element">{{ element.user.name }} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef class='messages__body'> Bericht </th>
                        <td mat-cell *matCellDef="let element" class='messages__body'> {{ element.message }} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="messages__action"> Actie </th>
                        <td mat-cell *matCellDef="let element" class="messages__action"> 
                            <button mat-mini-fab (click)="reviewMessage(element.id, true)" class='mat-elevation-z1 messages__button messages__button--approve'>
                                <span class="material-icons">done</span>
                            </button>
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                </table>

            </mat-tab>
          </mat-tab-group>
    </mat-card-content>
</mat-card>