import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormDialogComponent } from '../../helpers/dialogs/form-dialog/form-dialog.component';
import { ApiService } from 'src/app/services/api/api.service';
import { EventEmitterService } from 'src/app/services/event-emitter/event-emitter.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { validatorMatchWith } from 'src/app/validators/isEqualTo.validator';

@Component({
	selector: 'app-new-password',
	templateUrl: './new-password.component.html',
	styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
	
	private onDestroy$	: Subject<void> = new Subject<void>();
	resetPasswordForm 	: FormGroup;
	dialogRef           : MatDialogRef<FormDialogComponent>
	formDialog;
	token 				: string;
	formFields 			: object[];
	quizId 				: string;
	isLoggedIn 			: boolean;
	baseUrl             : string = "/";
	
	constructor(
		private fb                 : FormBuilder,
		private api  			   : ApiService,
		private dialog             : MatDialog,
		private eventEmitterService: EventEmitterService,
		private route			   : ActivatedRoute,
		private quiz			   : QuizService,
	) { }
		
	ngOnInit(): void {
		this.token = this.route.snapshot.queryParams.token;
		this.quizId = this.route.snapshot.queryParams.quizId;
		this.checkLogin();
		this.setQuiz();
		this.makeForm();
		this.setFormFields();

		let regex = new RegExp(/^\/admin\//);
		if (regex.test(window.location.pathname)) {
			this.baseUrl = "/admin/";
		}

		this.openDialog();
		this.eventEmitterService.startListening();
		
		if (
			this.eventEmitterService.subsVar==undefined ||
			this.eventEmitterService.subsVar==null
		) {   
			
			this.eventEmitterService.subsVar = 
			this
				.eventEmitterService
				.invokeFirstComponentFunction
				.pipe(takeUntil(this.onDestroy$))
				.subscribe((name:string) => {    
					this.resetPassword();    
				})
			;
		}  
	}

	setQuiz() {
		if(this.quizId) {
			localStorage.setItem('quizId', this.quizId);
		}
		this.quiz.getQuiz();
	}

	openDialog():Promise<boolean> {
		var thisComponent = this;
		return new Promise(function(resolve, reject) {
			thisComponent.dialogRef = thisComponent.dialog.open(FormDialogComponent,{
				data:{
					title       : 'Nieuw wachtwoord',
					text        : thisComponent.token
						? 'Vul hieronder twee keer je nieuwe achtwoord in'
						: 'Vul eerst je huidige wachtwoord in, en daarna twee keer je nieuwe wachtwoord.',
					doneText    : thisComponent.isLoggedIn
						? 'Je nieuwe wachtwoord is ingesteld.'
						: 'Je nieuwe wachtwoord is ingesteld. Klik op \'inloggen\' om in te loggen met je nieuwe wachtwoord',
					doneBtnText : thisComponent.isLoggedIn
						? 'Terug'
						: 'Inloggen',
					doneBtnLink : thisComponent.isLoggedIn
						? thisComponent.baseUrl + "/"
						: `${thisComponent.baseUrl}/login`,
					formGroup 	: thisComponent.resetPasswordForm,
					formFields: thisComponent.formFields,
					buttonText	: 'Wachtwoord resetten',
					submit 		: thisComponent.resetPassword,
					links 		: thisComponent.isLoggedIn
						? {"url": `${thisComponent.baseUrl}/`, "text": "Annuleren"}
						: {"url": `${thisComponent.baseUrl}/login`, "text": "Inloggen"},
					customCss   : "max-width: 400px"
				}
			});
			thisComponent.formDialog = thisComponent.dialogRef;
		})
	}

	
	makeForm() {
		this.resetPasswordForm = this.fb.group({
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(7)
			]),
			password_confirm: new FormControl('', [
				Validators.required,
				Validators.minLength(7),
				validatorMatchWith('password')
			])
		});
		if(!this.token) {
			this.resetPasswordForm.addControl(
				'password_old', 
				new FormControl('', [
					Validators.required,
					Validators.minLength(7)
				])
			);
		}
	}

	setFormFields() {
		this.formFields = [
			{
				'label'       : 'Nieuw wachtwoord',
				'formControl' : 'password',
				'type'        : 'password'
			},
			{
				'label'       : 'Nieuw wachtwoord (bevestigen)',
				'formControl' : 'password_confirm',
				'type'        : 'password'
			}
		];
		if(!this.token) {
			this.formFields.unshift(
				{
					'label'       : 'Oud wachtwoord',
					'formControl' : 'password_old',
					'type'        : 'password'
				}
			)
		}
	}

	async resetPassword() {
		let body = {
			password: this.resetPasswordForm.get('password').value,
			password_confirm: this.resetPasswordForm.get('password_confirm').value 
		}

		if(this.isLoggedIn) {
			body['password_old'] = this.resetPasswordForm.get('password_old').value ;
			await this.api.request("post", "/user/change_password", {body: body});
		} else {
			body['token'] = this.token;
			await this.api.request("post", "/user/reset_password", {body: body});
		}
		this.dialogRef.componentInstance.sent = true;
	}

	checkLogin():void {
		// TODO: echt checken of de gebruikre is ingelogd ipv alleen de local storage te checken;
		this.isLoggedIn = localStorage.getItem("jwt_token") ? true : false;	
	}

	public ngOnDestroy(): void {
		this.eventEmitterService.subsVar = null;
		this.dialog.closeAll();
		this.onDestroy$.next();
	}
}
		