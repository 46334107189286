import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ConfirmDialogComponent } from '../../helpers/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-quizzes-overview',
    templateUrl: './quizzes-overview.component.html',
    styleUrls: ['./quizzes-overview.component.scss']
})
export class QuizzesOverviewComponent implements OnInit {
    
    quizzes: object;
    isLoading: boolean = true;
    displayedColumns: string[] = ['id', 'name', 'date', 'delete'];

    constructor(
        private  api: ApiService,
        public dialog: MatDialog,
    ) { }
    
    async ngOnInit(): Promise<void> {
        await this.getQuizzes();
        this.isLoading = false;
    }
    
    async getQuizzes() {
        this.quizzes = await this.api.request("get", "all", {});
    }

    deleteQuiz(quiz) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: {
                message: "Weet je zeker dat je \""+quiz['name']+"\" wil verwijderen?"
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if(result) {
                await this.api.request("delete", "quiz/"+quiz['id']);
                this.quizzes = await this.api.request("get", "all", {});
            }
        });
    }
}
