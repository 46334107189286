import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	
	apiURL: string = environment.API_URL;
	
	constructor(
		private http    : HttpClient,
		private snackbar: MatSnackBar
	) {}

	request(type:string, endpoint:string, args?:object):Promise<object>|Promise<object[]> {
		let headers = new HttpHeaders();
		headers = headers.set('Authorization', "Bearer " + localStorage.getItem("jwt_token"));
		return new Promise(async (res, rej) => {
			try {
				switch(type) {
					case "get":
						let params = {};
						if(args) {
							params  = args['params'] || {};
						}
						var result = await this.http
							//TODO: fix params..
							.get(this.apiURL + endpoint, {headers: headers, params: params})
							.toPromise();
					break;
					case "post":
						var result = await this.http
							.post(this.apiURL + endpoint, args['body'], {headers: headers})
							.toPromise();
					break;
					case "put":
						if(!args['body']) {
							rej("No body found!");
						} else {
							var result = await this.http
							.put(this.apiURL + endpoint, args['body'], {headers: headers})
							.toPromise();
						}
					break;
					case "delete":
						var result = await this.http
							.delete(this.apiURL + endpoint, {headers: headers})
							.toPromise();
					break;
				}
				res(result);
			} catch(err) {
				if(args && args['ignoreErrors']) {
					rej(err.error);
				} else {
					this.snackbar.open(err.error.message, "Sluiten", {duration: 3000});
				}
			}
		});
	}
}
