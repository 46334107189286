<mat-card class="team-nakijken__wrapper" *ngIf="!isLoading">
    <mat-card-title>Nakijken: {{round['title']}}, vraag {{questionNumber}}</mat-card-title>
    <mat-card-subtitle>

    </mat-card-subtitle>

    <mat-card-content>
        <table mat-table [dataSource]="answers" class="full-width mat-table-alternate mat-elevation-z0 team-nakijken__table">
            <ng-container matColumnDef="amount">
                <th class="team-nakijken__cel-number" mat-header-cell *matHeaderCellDef> # </th>
                <td class="team-nakijken__cel-number" mat-cell *matCellDef="let element"><i> ({{element.amount}}) </i></td>
            </ng-container>

            <ng-container matColumnDef="answer">
                <th mat-header-cell *matHeaderCellDef> Antwoord </th>
                <td mat-cell *matCellDef="let element"> {{element.answer}} </td>
            </ng-container>

            <ng-container matColumnDef="action-correct">
                <th class='team-nakijken__action' mat-header-cell *matHeaderCellDef></th>
                <td class='team-nakijken__action' mat-cell *matCellDef="let element">
                    <button mat-mini-fab [class.active]="element.points > 0" (click)="reviewQuestion(element.number, element.answer, 1)" class='mat-table-alternate mat-elevation-z1 team-nakijken__action-button team-nakijken__action--correct'>
                        <span class="material-icons">done</span>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="action-incorrect">
                <th class='team-nakijken__action' mat-header-cell *matHeaderCellDef>
                    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf='device == "mobile"'>
                        <span class="material-icons">
                            more_vert
                        </span>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="reviewEverything(0)">Alles fout rekenen</button>
                        <button mat-menu-item (click)="reviewEverything(1)">Alles goed rekenen</button>
                    </mat-menu>
                </th>
                <td class='team-nakijken__action' mat-cell *matCellDef="let element">
                    <button mat-mini-fab color="warn" [class.active]="element.points !== null && element.points <= 0" (click)="reviewQuestion(element.number, element.answer, 0)" class='mat-table-alternate mat-elevation-z1 team-nakijken__action-button team-nakijken__action--incorrect'>
                        <span class="material-icons">clear</span>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="action-more">
                <th class='team-nakijken__action' mat-header-cell *matHeaderCellDef>

                    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf='device == "desktop"'>
                        <span class="material-icons">
                            more_vert
                        </span>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="reviewEverything(0)">Alles fout rekenen</button>
                        <button mat-menu-item (click)="reviewEverything(1)">Alles goed rekenen</button>
                    </mat-menu>

                </th>
                <td class='team-nakijken__action' mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="openDialog(element)" class='mat-table-alternate mat-elevation-z0'>
                        <span class="material-icons">more_vert</span>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mousedown)="longPress(row)" (touchstart)="longPress(row)" (mouseup)="mouseUp()" (touchend)="mouseUp()" [class.team-nakijken__row--score-indicator]="row.points && row.points !== 0 && row.points !== 1"></tr>
        </table>
    </mat-card-content>
    <mat-card-actions>
        <a mat-stroked-button color="primary" [routerLink]="'/admin/nakijken/vraag'">Annuleren</a>
        <a mat-flat-button color="primary"(click)="savePoints('overview')">Opslaan
            <span class="material-icons">
                reorder
            </span>
        </a>
        <!-- <a mat-flat-button color="primary" >Opslaan 
            <span class="material-icons">
                navigate_next
            </span>
        </a> -->
    </mat-card-actions>
</mat-card>