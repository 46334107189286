import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MomentDateAdapter} from '@angular/material-moment-adapter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminComponent } from './components/admin/admin/admin.component';
import { MaterialModule } from "./material";
import { HttpClientModule } from '@angular/common/http';
import { AdminControlComponent } from './components/admin/admin-control/admin-control.component';
import { SpinnerComponent } from './components/helpers/spinner/spinner.component';
import { RoundOverviewTeamsComponent } from './components/admin/round-overview-teams/round-overview-teams.component';
import { RoundOverviewQuestionsComponent } from './components/admin/round-overview-questions/round-overview-questions.component';
import { ReviewTeamComponent } from './components/admin/review-team/review-team.component';
import { ReviewQuestionComponentComponent } from './components/admin/review-question-component/review-question-component.component';
import { WrapperComponent } from './components/quiz/wrapper/wrapper.component';
import { AdminLoginComponent } from './components/admin/admin-login/admin-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { SplashComponent } from './components/quiz/splash/splash.component';
import { LoginComponent } from './components/quiz/login/login.component';
import { AnswersComponent } from './components/quiz/answers/answers.component';
import { FormDialogComponent } from './components/helpers/dialogs/form-dialog/form-dialog.component';
import { RegisterComponent } from './components/quiz/register/register.component';
import { SelectQuizComponent } from './components/quiz/select-quiz/select-quiz.component';
import { GameComponent } from './components/quiz/game/game.component';
import { SyncDialogComponent } from './components/helpers/dialogs/sync-dialog/sync-dialog.component';
import { EmptyDialogComponent } from './components/helpers/dialogs/empty-dialog/empty-dialog.component';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { OnboardingComponent } from './components/quiz/onboarding/onboarding.component';
import { ResultsComponent } from './components/admin/results/results.component';
import { MessagesComponent } from './components/admin/messages/messages.component';
import { MessagesBarComponent } from './components/messages-bar/messages-bar.component';
import { MessagesFormComponent } from './components/messages/messages-form/messages-form.component';
import { AngularResizedEventModule } from 'angular-resize-event';
import { ForgotPasswordComponent } from './components/quiz/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './components/quiz/new-password/new-password.component';
import { EditAccountComponent } from './components/quiz/user/edit-account/edit-account.component';
import { ReviewMoreInfoComponent } from './components/admin/review-more-info/review-more-info.component';
import { CreateQuizComponent } from './components/admin/create-quiz/create-quiz.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { QuizzesOverviewComponent } from './components/admin/quizzes-overview/quizzes-overview.component';
import { ConfirmDialogComponent } from './components/helpers/dialogs/confirm-dialog/confirm-dialog.component';
import { ParticipantsComponent } from './components/admin/participants/participants.component';
import { SearchUserModalComponent } from './components/admin/participants/search-user-modal/search-user-modal.component';
import { NotificationComponent } from './components/quiz/notification/notification.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AdminControlComponent,
    SpinnerComponent,
    RoundOverviewTeamsComponent,
    RoundOverviewQuestionsComponent,
    ReviewTeamComponent,
    ReviewQuestionComponentComponent,
    WrapperComponent,
    AdminLoginComponent,
    SplashComponent,
    LoginComponent,
    AnswersComponent,
    FormDialogComponent,
    RegisterComponent,
    SelectQuizComponent,
    GameComponent,
    SyncDialogComponent,
    EmptyDialogComponent,
    OnboardingComponent,
    ResultsComponent,
    MessagesComponent,
    MessagesBarComponent,
    MessagesFormComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    EditAccountComponent,
    ReviewMoreInfoComponent,
    CreateQuizComponent,
    QuizzesOverviewComponent,
    ConfirmDialogComponent,
    ParticipantsComponent,
    SearchUserModalComponent,
    NotificationComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    Nl2BrPipeModule,
    FormsModule,
    AngularResizedEventModule,
    MatDatepickerModule
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
    NotificationComponent,
    MomentDateAdapter,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
