<form class='review-more__form' [formGroup]="form" (ngSubmit)="submit(form)">
	<h1 mat-dialog-title>Punten vraag {{number}}</h1>
	<mat-dialog-content>
		<p>Antwoord: {{ answer }}</p>
	  <mat-form-field class='review-more__input'>
		<input matInput formControlName="points" placeholder="Punten">
	  </mat-form-field>
	  <h2 *ngIf="users.length != 1">Teams:</h2>
	  <h2 *ngIf="users.length == 1">Team:</h2>
	  <ul class='review-more__user-list'>
			<li *ngFor='let user of users'>{{ user }}</li>
	  </ul>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-button type="button" (click)="dialogRef.close()">Annuleren</button>
		<button mat-flat-button color="primary" type="submit">Opslaan</button>
	</mat-dialog-actions>
  </form>