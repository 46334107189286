import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { TOKEN_NAME } from '../../../services/authentication/authentication.service'
import { Router } from '@angular/router';
import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { trigger, transition, style, animate, keyframes, state } from '@angular/animations';
import { ApiService } from 'src/app/services/api/api.service';
import { SettingService } from 'src/app/services/settings/setting.service';

@Component({
	selector: 'app-wrapper',
	templateUrl: './wrapper.component.html',
	styleUrls: ['./wrapper.component.scss'],
	animations: [
		trigger("slideInOut", [
				state('desktop', style({width: "50%", height: "100vh", opacity: "1"})),
				state('mobile', style({width: "100%", height: "300px", opacity: "1"})),
				transition("* => *", animate(".7s ease-in-out"))
			]
		),
	]
})
export class WrapperComponent implements OnInit, OnDestroy {
	
			isLoading		: boolean       = true;
			isSideNavOpen	: boolean 		= false;
			isMessageOpen	: boolean 		= false;
			animation 	    : string        = window.innerWidth > 768 ? "desktop" : "mobile";
	private onDestroy$		: Subject<void> = new Subject<void>();
	
	constructor(
		public quiz        : QuizService,
		public authService : AuthenticationService,
		private router     : Router,
		private socket     : WebSocketService,
		private api		   : ApiService,
		public settings    : SettingService
	) { }
	
	ngOnInit(): void {
		var thisComponent = this;
		try {
			thisComponent.quiz.getQuiz().then(async () => {
				await thisComponent.socket.connect(this.quiz.url);
				this.settings.fetchSettings();
				this.settings.listen();

				this.socket
				.listener("current status")
				.pipe(takeUntil(this.onDestroy$))
				.subscribe((data) => {
					this.quiz.status['status'] = <string>data;
					if(data == "Offline") {
						this.router.navigateByUrl("/offline");
					} else {
						this.router.navigateByUrl("/");
					}
				})

				this.socket
					.listener("end quiz")
					.pipe(takeUntil(this.onDestroy$))
					.subscribe((data) => {
						this.quiz.status['status'] = "End";
					})
				;
				this.socket
					.listener("set serverSyncTime")
					.pipe(takeUntil(this.onDestroy$))
					.subscribe((data) => {
						this.socket.serverSyncTime = new Date().getTime();
					})
				;
				this.socket
					.listener("open sync dialog")
					.pipe(takeUntil(this.onDestroy$))
					.subscribe((data) => {
						this.socket.openSyncDialog();
					})
				;
				this.socket
					.listener("close sync dialog")
					.pipe(takeUntil(this.onDestroy$))
					.subscribe((data) => {
						this.socket.closeSyncDialog();
					})
				;
				this.socket
					.listener("clear sync")
					.pipe(takeUntil(this.onDestroy$))
					.subscribe((data) => {
						this.socket.timeout = 0;
					})
				;
				thisComponent.isLoading = false;
			});

		} catch(err) {
			console.log(err);
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.animation   = window.innerWidth > 768 ? "desktop" : "mobile";
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	
	logout(): void {
		localStorage.removeItem(TOKEN_NAME);
		this.router.navigateByUrl("/login");
	}

	changeHide(val: boolean) {
		this.isMessageOpen = val;
	  }
}
