import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { QuizService } from 'src/app/services/quiz/quiz.service';

@Injectable({
	providedIn: 'root'
})
export class OfflineGuard implements CanActivate {
	
	constructor(
		private quiz  : QuizService,
		private router: Router
	) {}
	
	async canActivate(route: ActivatedRouteSnapshot) {

		if(!this.quiz.status) {
			await this.quiz.getQuiz();	
		}
		var status = this.quiz.status['status'];
		if(status == "Offline") {
			this.router.navigate(['/offline']);
			return false;
		} else {
			return true;
		}
	}
}
	