import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	
	constructor(
		private router: Router, 
		private authService: AuthenticationService,
		private location: Location
	) {}
		
	canActivate() {
		if (!this.authService.isTokenExpired()) {
			return true;
		}

		var path = this.location.path();
		var regex = new RegExp(/^\/admin/);
		if(regex.test(path)) {
			this.router.navigate(['/admin/login']);	
		} else {
			let onboarding_seen = sessionStorage.getItem("SEEN_ONBOARDING");
			if(!onboarding_seen || onboarding_seen == "0") {
				this.router.navigate(['/landing']);  	
			} else {
				this.router.navigate(['/login']);	
			}
		}
		return false;
	}	
}