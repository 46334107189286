<div class="qm__wrapper" *ngIf="!isLoading2">
    <div class="antwoordenboekje">
      <div class="antwoordenboekje__content">
        <div class="naam">
          <div class="naam__label">Naam:</div>
          <div class="naam__value">{{ username }}</div>
        </div>

        <form class="antwoordenboekje__form" [formGroup]="antwoordFormulier">
          <div #antwoordenboekje__wrapper>
            <div class="antwoordenboekje__field" 
              formArrayName="answers" 
              *ngFor="let answer of antwoordFormulier.get('answers')['controls']; let i = index"
              [formGroup]="answer">
                <div class="answer" [formGroupName]="i">
                  <label class="antwoordenboekje__label">{{(i+1)}}</label>
                  <input 
                  class="antwoordenboekje__input antwoordenboekje__answer_{{(i+1)}}" 
                  type="text" 
                  name="answer" 
                  (keyup)="calcTime(i); writeToLocalStorage()" 
                  formControlName="value"
                  [readonly]="quiz.status['question'] > (i + 1)"
                  [class.active]="quiz.status['question'] == (i + 1)">
                </div>
  
            </div>
          </div>
        </form>
        <div class='antwoordenboekje__ronde'>{{ round['title'] }}</div>
      </div>
    </div>
  </div>