import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './components/admin/admin/admin.component';
import { AdminControlComponent } from './components/admin/admin-control/admin-control.component';
import { RoundOverviewTeamsComponent } from './components/admin/round-overview-teams/round-overview-teams.component';
import { ReviewTeamComponent } from './components/admin/review-team/review-team.component';
import { RoundOverviewQuestionsComponent } from './components/admin/round-overview-questions/round-overview-questions.component';
import { ReviewQuestionComponentComponent } from './components/admin/review-question-component/review-question-component.component';
import { WrapperComponent } from './components/quiz/wrapper/wrapper.component';
import { AdminLoginComponent } from './components/admin/admin-login/admin-login.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { AdminGuard } from './guards/admin/admin.guard';
import { LoginComponent } from './components/quiz/login/login.component';
import { SplashComponent } from './components/quiz/splash/splash.component';
import { RegisterComponent } from './components/quiz/register/register.component';
import { SelectQuizComponent } from './components/quiz/select-quiz/select-quiz.component';
import { GameComponent } from './components/quiz/game/game.component';
import { OfflineGuard } from './guards/offline/offline.guard';
import { OnboardingComponent } from './components/quiz/onboarding/onboarding.component';
import { ResultsComponent } from './components/admin/results/results.component';
import { MessagesComponent } from './components/admin/messages/messages.component';
import { MessagesBarComponent } from './components/messages-bar/messages-bar.component';
import { ForgotPasswordComponent } from './components/quiz/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './components/quiz/new-password/new-password.component';
import { EditAccountComponent } from './components/quiz/user/edit-account/edit-account.component';
import { CreateQuizComponent } from './components/admin/create-quiz/create-quiz.component';
import { SuperAdminGuard } from './guards/super_admin/super-admin.guard';
import { QuizzesOverviewComponent } from './components/admin/quizzes-overview/quizzes-overview.component';
import { ParticipantsComponent } from './components/admin/participants/participants.component';
import { QuizRoleGuard } from './guards/quiz-role/quiz-role.guard';
import { QuizRoles } from './services/authentication/authentication.service';


const routes: Routes = [
	{ 
		path: 'admin/login', 
		component: AdminLoginComponent 
	},
	{ 
		path: 'admin', 
		canActivate: [AuthGuard, AdminGuard],
		component: AdminComponent, children: 
		[
			{
			  path: '',
			  component: AdminControlComponent
			},
			{
			  path: 'uitslag',
			  component: ResultsComponent
			},
			{
			  path: 'berichten',
			  component: MessagesComponent
			},
			{
			  path: 'deelnemers',
			  component: ParticipantsComponent,
			  canActivate: [QuizRoleGuard],
			  data: {role: QuizRoles.Reviewer}
			},
			{
			  path: 'nakijken/teams',
			  component: RoundOverviewTeamsComponent
			},
			{
			  path: 'nakijken/teams/:user/:round',
			  component: ReviewTeamComponent	
			},
			{
			  path: 'nakijken/vraag',
			  component: RoundOverviewQuestionsComponent
			},
			{
			  path: 'nakijken/vraag/:round/:number',
			  component: ReviewQuestionComponentComponent
			},
			{
				path: 'quiz/alles',
				component: QuizzesOverviewComponent,
				canActivate: [SuperAdminGuard]
			},
			{
				path: 'quiz/nieuw',
				component: CreateQuizComponent,
				canActivate: [SuperAdminGuard]
			},
			{
				path: 'quiz/:id',
				component: CreateQuizComponent,
				canActivate: [SuperAdminGuard]
			},
			{
				path: 'account',
				component: EditAccountComponent
			},
			{
				path: 'account/wachtwoord',
				component: NewPasswordComponent
			}
		],
	},
	{ path: '', component: WrapperComponent, children: [
		{
			path: '',
			component: GameComponent,
			canActivate: [OfflineGuard, AuthGuard]
		},
		{
			path: 'login',
			component: LoginComponent,
			canActivate: [OfflineGuard]
		},
		{
			path: 'inschrijven',
			component: RegisterComponent,
			canActivate: [OfflineGuard]
		},
		{
			path: 'gebruiker',
			children: [
				{
					path: 'wijzigen',
					component: EditAccountComponent,
					canActivate: [AuthGuard]
				}
			]
		},
		{
			path: 'wachtwoord_vergeten',
			component: ForgotPasswordComponent
		},
		{
			path: 'reset_wachtwoord',
			component: NewPasswordComponent
		},
		{
			path: 'landing',
			component: OnboardingComponent,
			canActivate: [OfflineGuard]
		},
		{
			path: 'offline',
			component: SplashComponent
		},
	]},
	{
		path: 'berichten/:quizId',
		component: MessagesBarComponent
	},
	{ 
		path: ':quizUrl', 
		component: SelectQuizComponent
	}
];


@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
