<div class="admin__wrapper" *ngIf="!isLoading">
    <mat-toolbar color="primary" role="heading">
        <button mat-icon-button (click)="sideNav.toggle()">
            <span class="material-icons">
            menu
            </span>
        </button>
        Quizmasters Live
        <span class="fill-remaining-space"></span>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <span class="material-icons">
                folder
            </span>
        </button>
        
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let quiz of allQuizzes" routerLink="/admin" (click)="setActiveQuiz(quiz['quiz']['id'])">{{quiz['quiz']['name']}}</button>
            <mat-divider *ngIf="user.getRole() === 'super_admin'"></mat-divider>
            <button mat-menu-item *ngIf="user.getRole() === 'super_admin'" routerLink="/admin/quiz/nieuw">Nieuw</button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="accountMenu">
            <span class="material-icons">
                face
            </span>
        </button>
        
        <mat-menu #accountMenu="matMenu">
            <button mat-menu-item routerLink="/admin/account">Account</button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logout()">Uitloggen</button>
        </mat-menu>
    </mat-toolbar>

    <mat-sidenav-container>
        <mat-sidenav #sideNav [mode]="sideNavMode" [opened]="isSideNavOpen">
            <mat-nav-list>
                <a mat-list-item [routerLink]="'/admin/'" (click)="subMenuOpen = ''; sideNav.mode === 'side' || sideNav.close()"> Status </a>
                <a *ngIf="user.hasRights(roles.Reviewer)" mat-list-item (click)="subMenuOpen = 'review'; sideNav.mode === 'side' || sideNav.close()"> Nakijken </a>
                <mat-nav-list *ngIf="user.hasRights(roles.Reviewer)" class="subMenu" [class.open]="subMenuOpen == 'review'">
                    <a mat-list-item [routerLink]="'/admin/nakijken/teams'" (click)="sideNav.mode === 'side' || sideNav.close()"> Per team </a>
                    <a mat-list-item [routerLink]="'/admin/nakijken/vraag'" (click)="sideNav.mode === 'side' || sideNav.close()"> Per vraag </a>
                </mat-nav-list>
                <a *ngIf="user.hasRights(roles.Moderator)" mat-list-item [routerLink]="'/admin/berichten'" (click)="subMenuOpen = ''; sideNav.mode === 'side' || sideNav.close()"> Berichten </a>
                <a *ngIf="user.hasRights(roles.Reviewer)" mat-list-item [routerLink]="'/admin/uitslag'" (click)="subMenuOpen = ''; sideNav.mode === 'side' || sideNav.close()"> Uitslag </a>
                <a *ngIf="user.hasRights(roles.Moderator)" mat-list-item [routerLink]="'/admin/deelnemers'" (click)="subMenuOpen = ''; sideNav.mode === 'side' || sideNav.close()"> Deelnemers </a>
                <ng-container *ngIf="user.getRole() == 'super_admin'">
                    <mat-divider></mat-divider>
                    <a mat-list-item [routerLink]="'/admin/quiz/alles'" (click)="subMenuOpen = ''; sideNav.mode === 'side' || sideNav.close()"> Quizzen </a>
                </ng-container>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>