<mat-card>
    <mat-card-title>Uitslag</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
    <mat-card-content>

        <mat-checkbox 
            [checked]="showRounds"
            (change)="toggleRoundColumns()"
        >
            Rondes tonen
        </mat-checkbox>
        <table mat-table *ngIf="!isLoading" [dataSource]="results" class="full-width mat-table-alternate mat-elevation-z0">
            <ng-container matColumnDef="user_name">
                <th mat-header-cell *matHeaderCellDef> Naam </th>
                <td mat-cell *matCellDef="let element">{{ element.user }} </td>
            </ng-container>
              
            <ng-container matColumnDef="nr">
                <th class="ranking__score-nr" mat-header-cell *matHeaderCellDef> # </th>
                <td class="ranking__score-nr" mat-cell *matCellDef="let element; let i = index">{{ (i + 1) }} </td>
            </ng-container>

            <ng-container *ngFor="let col of displayedColumns">
                <ng-container [matColumnDef]="col" *ngIf="showRounds && col != 'user_name' && col != 'nr' && col != '_total'">
                    <th mat-header-cell *matHeaderCellDef class='ranking__score-round'> {{ getRoundNr(col) }} </th>
                    <td mat-cell *matCellDef="let element" class='ranking__score-round'> {{ element['points'][col] }} </td>
                </ng-container>
            </ng-container>

            <ng-container matColumnDef="_total">
                <th mat-header-cell *matHeaderCellDef class="ranking__score-total">Totaal</th>
                <td mat-cell *matCellDef="let element" class="ranking__score-total"> <b>{{ element['total'] }}</b> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

    </mat-card-content>