<div class="splash__wrapper" #splash__wrapper (resized)="onResized($event)" [class.splash__wrapper--small]="splash__wrapper.offsetWidth < 768">
    <div class="splash__papier">
        <div class="splash__texts-wrapper">
            <h2 class="splash__title" [innerHTML]="title"></h2>
            <div class="splash__content">{{text}}</div>
        </div>
        <div class="splash__viltje">
            <div class="splash__viltje-tekst">
                {{viltje}}
            </div>
        </div>
    </div>
</div>