<mat-card class='edit-profile__wrapper' *ngIf="!isLoading">
    <mat-card-header>
        <mat-card-title>{{ name }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>Hieronder zie je de gegevens die bij ons bekend zijn. Als je dingen aan wilt passen dan kan je dat hier doen. Klik als je klaar bent op "Opslaan".</p>
        <div *ngIf="error" class="edit-profile__error-msg">{{ error }}</div>
        <form class="edit-profile__form" [formGroup]="editForm" (submit)="submitForm()" *ngIf="!done">
            <mat-form-field class='edit-profile__form-group'>
                <mat-label>E-mailadres</mat-label>
                <input matInput type="email" formControlName="email">
                <mat-error *ngIf="editForm.controls['email'].errors">
                    <div *ngIf="editForm.controls['email'].errors.required">
                        Dit veld is verplicht
                    </div>
                    <div *ngIf="editForm.controls['email'].errors.email">
                        Dit is geen geldig emailadres
                    </div>
                </mat-error>
            </mat-form-field>

            <mat-form-field class='edit-profile__form-group'>
                <mat-label>(Team) naam</mat-label>
                <input matInput type="text" formControlName="name">
                <mat-error *ngIf="editForm.controls['name'].errors">
                    <div *ngIf="editForm.controls['name'].errors.required">
                        Dit veld is verplicht
                    </div>
                    <div *ngIf="editForm.controls['name'].errors.minlength">
                        Dit veld moet minimmaal {{editForm.controls['name'].errors.minlength.requiredLength}} tekens bevatten
                    </div>
                </mat-error>
            </mat-form-field>
            <a class="edit-profile__change-password" [routerLink]="passwordUrl">Wachtwoord wijzigen</a>
        </form>
        <div *ngIf="done">
            Je wijzigingen zijn opgeslagen
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button 
            *ngIf="done"
            mat-button 
            color="primary" 
            [routerLink]="baseUrl"
        >
            Terug
        </button>
        <button 
            *ngIf="!done"
            mat-stroked-button 
            color="primary" 
            [routerLink]="baseUrl"
        >
            Annuleren
        </button>
        <button 
            *ngIf="!done"
            mat-raised-button 
            color="primary" 
            [disabled]="!editForm.valid" 
            (click)="submitForm()"
        >
            Opslaan
        </button>
    </mat-card-actions>
</mat-card>