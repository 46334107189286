import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment'

@Injectable({
	providedIn: 'root'
})
export class QuizService {
	
	id      : string;
	url     : string;
	youtube : string;
	date    : Date;
	status  : object;
	rounds  : object[];
	texts  	: object[];
	settings: object;
	isPending: boolean = true;

	
	constructor(
		private api     : ApiService,
		private snackbar: MatSnackBar,
		private route   : ActivatedRoute,
	) { }
	
	async getQuiz() {
		this.isPending = true;
		if(!sessionStorage.getItem("quizId")) {
			sessionStorage.setItem("quizId", environment.default_quiz_id);
		}
		if(this.route.snapshot.queryParams.quizId) {
			sessionStorage.setItem("quizId", this.route.snapshot.queryParams.quizId);
		}
		let quizId = sessionStorage.getItem("quizId");
		return new Promise(async (res, rej) => {
			if(!quizId) {
				this.snackbar.open("Geen quiz gevonden", 'Sluiten');
			}
			if(quizId != this.id) {
				var quiz = await this.api.request("get", "quiz/"+quizId);
				this.setProps(quiz);
				this.isPending = false;
				res();
			} else {
				this.isPending = false;
				res();
			}
		});
	}
		
	getByUrl(url) {
		return true;
	}

	setProps(quiz) {
		this.id       = <string>quiz['id'];
		this.url      = <string>quiz['url'];
		this.youtube  = <string>quiz['youtube'];
		this.date     = new Date(quiz['date']);
		this.status   = <object>quiz['status'];
		this.rounds   = <object[]>quiz['rounds'];
		this.texts    = <object[]>quiz['texts'];
		this.settings = <object[]>quiz['setting'];
	}

	getId() {
		return this.id ? this.id : sessionStorage.getItem("quizId");
	}
}
