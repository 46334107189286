import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from "@auth0/angular-jwt";

const jwt = new JwtHelperService();

@Component({
	selector: 'app-answers',
	templateUrl: './answers.component.html',
	styleUrls: ['./answers.component.scss']
})
export class AnswersComponent implements OnInit {
	
	round            	: object;
	answers				: object[];
	username   		 	: string  = localStorage.getItem("name");
	timeStart  		 	: number  = 0;
	isLoading2 		 	: boolean = true;
	antwoordFormulier	: FormGroup;
	private onDestroy$	: Subject<void> = new Subject<void>();
	@ViewChild("antwoordenboekje__wrapper") antwoordenboekjeWrapper: ElementRef;
	
	constructor(
		private snackbar: MatSnackBar,
		private socket	: WebSocketService,
		public  quiz  	: QuizService,
		private api   	: ApiService,
		private fb    	: FormBuilder
	) { }
	
	ngOnInit(): void {
		this.getActiveRound();
		this.makeForm();
		this.timeStart = performance.now();
		var that = this;
		setTimeout(function() {
			that.isLoading2 = false;
			that.focusOnQuestion();
		},1100);

		this.socket
			.listener("current question")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.quiz.status['question'] = <number>data;
				this.focusOnQuestion();
				this.timeStart = performance.now();
			})
		;

		this.socket
			.listener("current round")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(async (data) => {
				var round   	= this.antwoordFormulier.get("round").value
				var answers 	= this.antwoordFormulier.get("answers").value
				var shouldSend 	= false

				// for(let i = 0; i < answers.length; i++) {
				// 	if(answers[i].value != "") {
				// 		shouldSend = true;
				// 		break;
				// 	}
				// }
				
				// if(shouldSend == true) {
				// 	this.api.request("post", "answers/"+round, {body: {answers: answers}})
				// } else {
				// 	// this.snackbar.open("Je antwoorden zijn niet opgeslagen omdat je niets hebt ingevuld", "Sluiten", {duration: 4000});
				// }

				this.api.request("post", "answers/"+round, {body: {answers: answers}})

				this.quiz.status['question'] =  0;
				this.quiz.status['round']    =  <number>data;

				this.getActiveRound();
				this.makeForm();
				this.timeStart = performance.now();
				this.focusOnQuestion();
			})
		;

		this.socket
			.listener("end quiz")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				var round   = this.antwoordFormulier.get("round").value
				var answers = this.antwoordFormulier.get("answers").value

				this.api.request("post", "answers/"+round, {body: {answers: answers}})
			})
		;
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
	}

	getUserAnswers(): Promise<void> {
		return new Promise(async (res, rej) => {
			let dbAnswers      = [];
			let storageAnswers = JSON.parse(sessionStorage.getItem(`answers::${this.round['id']}`));

			try {
				let decoded_token = jwt.decodeToken(localStorage.getItem("jwt_token"));
				dbAnswers = await <Promise<object[]>>this.api.request("get", "/answers/user/"+decoded_token['id']+"/"+this.quiz.status['round'])
			} catch(err) {
				console.log(err);
			}

			// overwrite the database answers with the answers stored in the session storage
			if (storageAnswers) {
				for (let answer of storageAnswers) {
					if (answer['value']) {
						
						// find the db answer
						let dbAnswer = dbAnswers.filter((obj) => {
							return obj['number'] == answer['nr'];
						});

						// overwrite the db answer if found
						if (dbAnswer.length > 0) {
							dbAnswer[0]['answer'] = answer['value'];
						} else {
							console.log("heeljaas :(");
							// no db answer found, so push a new entry to the array
							dbAnswers.push(
								{
									number: answer['nr'],
									answer: answer['value'],
									time: answer['time']
								}
							);
						}
					}
				}
			}
			console.log(dbAnswers);
			this.answers = dbAnswers;
			res();
		});
	}

	getActiveRound() {
		this.round = this.quiz.rounds.filter(obj => { return obj['id'] == this.quiz.status['round']; })[0];
	}
	
	async makeForm() {
		this.antwoordFormulier = this.fb.group({
			user_id: new FormControl(localStorage.getItem("user_id"), []),
			round  : new FormControl(this.round['id'], []),
			answers: new FormArray([])
		});
		await this.getUserAnswers();
		for(let i = 0; i < this.round['questions']; i++) {
			var answer = this.answers.filter(obj => { return obj['number'] == (i + 1) });
			var answerValue = answer.length > 0 ? answer[0]['answer'] : "";

			(<FormArray>this.antwoordFormulier.get('answers')).push(this.fb.group({
				nr: (i + 1),
				value: answerValue,
				time: 0
			}))
		}
	}

	calcTime(question) {
		// TODO: fix the quick and dity thing
		question = "" + question;

		let timeEnd : number = performance.now();
		let diff    : number = Math.round(timeEnd - this.timeStart);
		let formGroup: FormGroup = <FormGroup>this.antwoordFormulier.get("answers").get(question);
	
		// TODO: check if field is readonly
	
		formGroup.get("time").setValue(diff)
	}

	writeToLocalStorage() {
		sessionStorage.setItem(`answers::${this.round['id']}`, JSON.stringify(this.antwoordFormulier.value.answers));
	}

	focusOnQuestion() {
		if(this.antwoordenboekjeWrapper) {
			for(let i = 1; i <= this.round['questions']; i++) {
				var answersWrapper = this.antwoordenboekjeWrapper.nativeElement;
				var thisInput = answersWrapper.querySelector(".antwoordenboekje__answer_"+i)
				if(i == this.quiz.status['question']) {
					thisInput.focus();
				}
			}
		}
	}
}
	