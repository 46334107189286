import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class AdminGuard implements CanActivate {
	
	constructor(
		private router: Router, 
		private authService: AuthenticationService,
		private snackbar: MatSnackBar
	) {}
	
	canActivate() {
		var role = this.authService.getRole();
		var quizAdmin = this.authService.isQuizAdmin();
		
		if (role == "admin" || role == "super_admin" || quizAdmin) {
			return true;
		} else {
			this.snackbar.open("Je hebt niet voldoende rechten", 'Sluiten', {duration: 3000});
			this.router.navigate(['/admin/login']);	
			return false;
		}
	}
}
	