import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { ApiService } from 'src/app/services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker/datepicker-input-base';

export const MY_FORMATS = {
	parse: {
	  dateInput: 'LL',
	},
	display: {
	  dateInput: 'LL',
	  monthYearLabel: 'MMM YYYY',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'MMMM YYYY',
	},
  };

@Component({
	selector: 'app-create-quiz',
	templateUrl: './create-quiz.component.html',
	styleUrls: ['./create-quiz.component.scss'],
	providers: [
	  // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
	  // application's root module. We provide it at the component level here, due to limitations of
	  // our example generation script.
	  {
		provide: DateAdapter,
		useClass: MomentDateAdapter,
		deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
	  },
  
	  {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	],
})
export class CreateQuizComponent implements OnInit {
	
	quizFormGroup: FormGroup;
	isLoading    : boolean = true;
	hours		 : string[] = [];
	minutes      : string[] = [];
	time         : object = {
		"hour": this.addLeadingZero(new Date().getHours()),
		"minute": this.addLeadingZero(new Date().getMinutes()),
	}
	openAtTime   : object = {
		"hour": this.addLeadingZero(new Date().getHours()),
		"minute": this.addLeadingZero(new Date().getMinutes()),
	}
	quiz         : object = {
		"name": "",
		"url": "",
		"date": moment(),
		"openAt": moment(),
		"youtube": "",
		"rounds": [],
		"texts": {
			"offline_title": "",
			"offline_text": "",
			"offline_viltje": "",
			"ready_title": "",
			"ready_text": "",
			"ready_viltje": "",
			"end_title": "",
			"end_text": "",
			"end_viltje": "",
		}
	};
	
	constructor(
		private fb: FormBuilder,
		private api: ApiService,
		private route: ActivatedRoute,
		private router: Router,
	) { }
	
	async ngOnInit(): Promise<void> {
		await this.getQuiz();
		this.getRounds();
		this.makeForm();
		this.isLoading = false;
	}
	
	async getQuiz() {
		if(this.route.snapshot.url[1].path !== 'nieuw') {
			this.quiz = await this.api.request("get", "/quiz/"+this.route.snapshot.params.id);
			this.time = {
				hour: this.addLeadingZero(new Date(this.quiz['date']).getHours()),
				minute: this.addLeadingZero(new Date(this.quiz['date']).getMinutes()),
			}
			this.openAtTime = {
				hour: this.addLeadingZero(new Date(this.quiz['openAt']).getHours()),
				minute: this.addLeadingZero(new Date(this.quiz['openAt']).getMinutes()),
			}
		}
	}

	makeForm():void {
		for(let i = 0; i <= 23; i++) {
			let hour = i < 10 ? '0' + i : i.toString();
			this.hours.push(hour);
		}
		for(let i = 0; i <= 59; i++) {
			let minute = i < 10 ? '0' + i : i.toString();
			this.minutes.push(minute);
		}

		this.quizFormGroup = this.fb.group({
			name       : new FormControl(this.quiz['name'], [Validators.required]),
			date       : new FormControl(this.quiz['date'], [Validators.required]),
			openAt     : new FormControl(this.quiz['openAt'], []),
			url        : new FormControl(this.quiz['url'], [Validators.required]),
			// domain     : new FormControl("localhost:4200", [Validators.required]),
			// identifier        : new FormControl(this.quiz['identifier'], [Validators.required]),
			youtube    : new FormControl(this.quiz['youtube'], []),
			rounds     : new FormArray(this.quiz['rounds']),
			texts      : new FormGroup({
				offline : new FormGroup({
					title :  new FormControl(this.quiz['texts']['offline_title'], [Validators.required]),
					text  :  new FormControl(this.quiz['texts']['offline_text'], [Validators.required]),
					viltje:  new FormControl(this.quiz['texts']['offline_viltje'], []),
				}),
				ready : new FormGroup({
					title :  new FormControl(this.quiz['texts']['ready_title'], [Validators.required]),
					text  :  new FormControl(this.quiz['texts']['ready_text'], [Validators.required]),
					viltje:  new FormControl(this.quiz['texts']['ready_viltje'], []),
				}),
				end : new FormGroup({
					title :  new FormControl(this.quiz['texts']['end_title'], [Validators.required]),
					text  :  new FormControl(this.quiz['texts']['end_text'], [Validators.required]),
					viltje:  new FormControl(this.quiz['texts']['end_viltje'], []),
				}),
			})
		});
		if(this.quiz['id']) {
			this.quizFormGroup.addControl('id', new FormControl(this.quiz['id']));
		}
	}

	formGroupRound(id: number = null, order: number = 0, title: string = '', questions: number = 10):FormGroup {
		if(id) {
			return new FormGroup({
				id: new FormControl(id),
				order: new FormControl(order),
				title: new FormControl(title, [Validators.required]),
				questions: new FormControl(questions, [Validators.required])
			})
		} else {
			return new FormGroup({
				order: new FormControl(order),
				title: new FormControl(title, [Validators.required]),
				questions: new FormControl(questions, [Validators.required])
			})
		}
	}

	addRound() {
		var order = (<FormArray>this.quizFormGroup.get('rounds')).length + 1;
		(<FormArray>this.quizFormGroup.get('rounds')).push(this.formGroupRound(null, order, '', 10));
	}

	setOrder() {
		let rounds = this.getControls(this.quizFormGroup, 'rounds');
		for(let i = 0; i < rounds.length; i++) {
			(<FormControl>rounds[i].get('order')).setValue((i + 1));
		}
	}

	deleteRound(index) {
		(<FormArray>this.quizFormGroup.get('rounds')).removeAt(index);
		this.setOrder();
	}

	getControls(frmGrp: FormGroup, key: string) {
		return (<FormArray>frmGrp.controls[key]).controls;
	}
	
	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.getControls(this.quizFormGroup, 'rounds'), event.previousIndex, event.currentIndex);
		this.setOrder();
	}

	async saveQuiz() {
		try {
			await this.api.request("post", "quiz", {body: this.quizFormGroup.value});
			this.router.navigateByUrl("/admin/quiz/alles");
		} catch(err) {
			// Default error handler
		}
	}

	editTitle(event) {
		if(!this.quizFormGroup.get('texts').get('offline').get('title').touched) {
			this.quizFormGroup.get('texts').get('offline').get('title').setValue(event.target.value);
		}
		if(!this.quizFormGroup.get('texts').get('ready').get('title').touched) {
			this.quizFormGroup.get('texts').get('ready').get('title').setValue(event.target.value);
		}
		if(!this.quizFormGroup.get('texts').get('end').get('title').touched) {
			this.quizFormGroup.get('texts').get('end').get('title').setValue(event.target.value);
		}
	}

	editViltje(event) {
		if(!this.quizFormGroup.get('texts').get('offline').get('viltje').touched) {
			this.quizFormGroup.get('texts').get('offline').get('viltje').setValue(event.target.value);
		}
		if(!this.quizFormGroup.get('texts').get('ready').get('viltje').touched) {
			this.quizFormGroup.get('texts').get('ready').get('viltje').setValue(event.target.value);
		}
		if(!this.quizFormGroup.get('texts').get('end').get('viltje').touched) {
			this.quizFormGroup.get('texts').get('end').get('viltje').setValue(event.target.value);
		}
	}

	changeTime(target: string, event) {
		let date = new Date(this.quizFormGroup.get('date').value);
		if(target === 'hour') {
			date.setHours(event.value);
		}
		if(target === 'minute') {
			date.setMinutes(event.value);
		}
		date.setSeconds(0);
		date.setMilliseconds(0);
		this.quizFormGroup.get('date').setValue(date);
	}

	changeOpenTime(target: string, event) {
		let date = new Date(this.quizFormGroup.get('openAt').value);
		if(target === 'hour') {
			date.setHours(event.value);
		}
		if(target === 'minute') {
			date.setMinutes(event.value);
		}
		date.setSeconds(0);
		date.setMilliseconds(0);
		this.quizFormGroup.get('openAt').setValue(date);
	}

	getRounds() {
		let tmp_rounds = [];
		this.quiz['rounds'].sort((a, b) => {	
			if(a.order < b.order) {
				return -1;
			}
			if(a.order > b.order) {
				return 1;
			}
			return 0;
		})
		if(this.quiz['rounds'].length == 0) {
			tmp_rounds.push(this.formGroupRound(null, 1, '', 10));
		} else {
			for(let i = 0; i < this.quiz['rounds'].length; i++) {
				tmp_rounds.push(this.formGroupRound(this.quiz['rounds'][i]['id'], this.quiz['rounds'][i]['order'], this.quiz['rounds'][i]['title'].toString(), this.quiz['rounds'][i]['questions']));
			}
		}
		this.quiz['rounds'] = tmp_rounds;
	}

	setDateTest(event: MatDatepickerInputEvent<Date>) {
		let date = new Date(this.quizFormGroup.get('openAt').value);
		const newDate = new Date(event.value);

		let newDate2 = date.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
		this.quizFormGroup.get('openAt').setValue(moment(newDate2));
	}

	addLeadingZero(value) {
		return value < 10 ? '0' + value : value.toString();
	}
}
