<mat-card class="onboarding__wrapper">
    <mat-horizontal-stepper [linear]="true" #stepper>
        <ng-template matStepperIcon="edit">
            <mat-icon>check</mat-icon>
        </ng-template>
        <mat-step [stepControl]="firstFormGroup">
            <ng-template matStepLabel>Welkom</ng-template>
            <div>
                <h2>Welkom</h2>
                Leuk dat je mee doet met deze quiz. In de volgende stappen volgt een korte uitleg over dit antwoordformulier.
                <br><br>
                <h3>Livestream</h3>
                De vragen van deze quiz worden gesteld via een livestream op YouTube. 
                <ng-container *ngIf="quiz.youtube">
                    De livestream kan je vinden via  <a [href]='quiz.youtube' target="_blank">deze url</a>.
                </ng-container>
                <ng-container *ngIf="! quiz.youtube">
                    De livestream kan je vinden op ons <a href="https://www.youtube.com/c/dequizmasters" target="_blank">YouTube kanaal</a>.
                </ng-container>
                <br><br>
                <b>Tip! </b> Heb je een Chromecast of smart-TV? speel de livestream dan af op je TV!
                <br><br>
            <button mat-flat-button color="primary" matStepperNext>Volgende</button>
            </div>
        </mat-step>
        <mat-step>
        <ng-template matStepLabel>Formulier</ng-template>
            <h2>Formulier</h2>
            De vragen die via de livestream worden gesteld kun je beantwoorden via het antwoordformulier op de website/pagina waarin je je nu bevindt.
            Zodra de volgende vraag wordt gesteld, worden de eerdere vragen geblokkeerd.
            Je kan je antwoord achteraf dus niet meer aanpassen.
            <br><br>
            <h3>Synchroniseren</h3>
            Om er voor te zorgen dat het antwoordformulier gelijk loopt met de YouTube stream komt er een aantal keer tijdens de quiz deze knop in beeld.
            <br>
            <img class="onboarding__syncButton" src='/assets/images/knop1.png'>
            <div class="onboarding__syncButton-label">(voorbeeld)</div>
            <br>
            Het is van belang dat je daar pas op drukt als dat duidelijk wordt gezegd in de livestream.
            <br><br>
            <div>
                <button mat-stroked-button color="primary" matStepperPrevious style="margin-right: 10px">Terug</button>
                <button mat-flat-button color="primary" matStepperNext>Volgende</button>
            </div>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>Account</ng-template>
            <div>
                <h2>Account</h2>
                Om mee te spelen heb je een account nodig. 
                Dit account kun je aanmaken in de laatste stap.
                <br><br>
                <button mat-stroked-button color="primary" matStepperPrevious style="margin-right: 10px">Terug</button>
                <button mat-flat-button color="primary" matStepperNext>Volgende</button>
            </div>
        </form>
        </mat-step>
        <mat-step>
        <ng-template matStepLabel>Teams</ng-template>
            <h2>Teams</h2>
            Alleen meespelen is leuk, maar met een team kan natuurlijk ook! 
            Als je met een team mee speelt, let dan wel op het volgende:
            Per team kan er maar <b>1 gebruiker</b> ingelogd zijn in het antwoordformulier.
            Alle rondes worden maar 1 keer opgeslagen. Mochten er dus meerdere mensen op hetzelfde account zijn ingelogd,
            dan gaan alle andere antwoordformulieren verloren.
            <br><br>
            <div>
                <button mat-stroked-button color="primary" matStepperPrevious style="margin-right: 10px">Terug</button>
                <button mat-flat-button color="primary" matStepperNext>Volgende</button>
            </div>
        </mat-step>
        <mat-step>
        <ng-template matStepLabel>Aanmelden</ng-template>
            <h2>Aanmelden</h2>
            Als je alles goed hebt doorgelezen dan kan je nu een account aanmaken. 
            We je veel succes en natuurlijk plezier met deze quiz.
            <br><br>
            <div>
                <button mat-stroked-button color="primary" matStepperPrevious style="margin-right: 10px">Terug</button>
                <br><br>    
                <button mat-flat-button color="primary" routerLink="/login" style="margin-right: 10px;" (click)="setViewed()">Inloggen</button>
                <button mat-flat-button color="primary" routerLink="/inschrijven" (click)="setViewed()">Registreren</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</mat-card>