import { Component, OnInit } from '@angular/core';
import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  text: string = "";
  notifications: object[] = [];

  constructor(
    public socket: WebSocketService
  ) { }

  ngOnInit(): void {
    this.listenForDisconnect();
  }

  listenForDisconnect() {
    this.socket.socket.on('disconnect', () => {
      let newNotification = {
        key : 'disconnected',
        text: 'De verbinding met de server is verbroken. We proberen opnieuw te verbinden.'
      }

      this.socket.socket.on('connect', () => {
        for (let i = 0; i < this.notifications.length; i++) {
          this.notifications.splice(i, 1);
        }
      })
      this.notifications.push(newNotification);
    });
  }
}
