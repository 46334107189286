<mat-card>
    <mat-card-title>Quizzen overzicht</mat-card-title>
    <mat-card-content>
        <table mat-table *ngIf="!isLoading" [dataSource]="quizzes" class="full-width mat-table-alternate mat-elevation-z0">
            <ng-container matColumnDef="id">
                <th class='quiz-overview__id-cell' mat-header-cell *matHeaderCellDef> ID </th>
                <td class='quiz-overview__id-cell quiz-overview__row-link' mat-cell *matCellDef="let element" [routerLink]="'/admin/quiz/' + element.id">{{ element.id }} </td>
            </ng-container>
              
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Naam </th>
                <td class='quiz-overview__row-link' mat-cell *matCellDef="let element" [routerLink]="'/admin/quiz/' + element.id">{{ element.name }} </td>
            </ng-container>
              
            <ng-container matColumnDef="date">
                <th class="quiz-overview__date-cell" mat-header-cell *matHeaderCellDef> Datum </th>
                <td class='quiz-overview__row-link quiz-overview__date-cell' mat-cell *matCellDef="let element" [routerLink]="'/admin/quiz/' + element.id">{{ element.date | date: 'd MMMM yyyy'}} </td>
            </ng-container>
              
            <ng-container matColumnDef="delete">
                <th class='quiz-overview__delete-cell' mat-header-cell *matHeaderCellDef></th>
                <td class='quiz-overview__delete-cell' mat-cell *matCellDef="let element">
                    <button mat-icon-button color="warn" (click)="deleteQuiz(element)">
                        <span class="material-icons"> delete </span>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class='quiz-overview__table-row' mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-flat-button color="primary" routerLink="/admin/quiz/nieuw">Nieuwe quiz</button>
    </mat-card-actions>
</mat-card>