import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
	
	message: string = '';
	cancel : string = 'Annuleren';
	confirm: string = 'Bevestigen';

	constructor(
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: object
	) { }
		
	ngOnInit() {
		this.message = this.data['message'];
		if(this.data['options']) {
			if(this.data['optioins']['cancel']) {
				this.cancel = this.data['options']['cancel'];
			}
			if(this.data['optioins']['confirm']) {
				this.confirm = this.data['options']['confirm'];
			}
		}
	}

	onNoClick(): void {
		this.dialogRef.close();		
	}
}