import { Component, OnInit, OnDestroy } from '@angular/core';
import { StatusService, quizStatus } from 'src/app/services/status/status.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-admin-control',
	templateUrl: './admin-control.component.html',
	styleUrls: ['./admin-control.component.scss']
})
export class AdminControlComponent implements OnInit, OnDestroy {

	isLoading     : boolean = true;
	adminForm     : FormGroup;
	round         : object;
	syncDialogOpen: boolean = false;
	private onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		public spinner	: SpinnerService,
		public quiz   	: StatusService,
		private fb		: FormBuilder,
		private socket	: WebSocketService
	) { }
	
	ngOnInit(): void {
		this.makeForm();

		let tmpRound = this.quiz.rounds.filter(obj => obj['id'] == this.quiz.status['round']);
		if(tmpRound) {
			this.round = tmpRound[0];
		}
		this.isLoading = false;

		this.socket
			.listener("current question")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.quiz.status['question'] = <number>data;
			})
		;

		this.socket
			.listener("current round")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.quiz.status['question'] = 0;
				this.quiz.status['round'] = data;
				this.round = this.quiz.rounds.filter(obj => { return obj['id'] == data })[0];
			})
		;

		this.socket
			.listener("end quiz")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.quiz.status['round']   = data;
				this.round                  = this.quiz.rounds.filter(obj => { return obj['order'] == 1 })[0];
				this.quiz.status['question']= 0;
				this.quiz.status['status'] 	= quizStatus.END;
				// this.adminForm.get("status").setValue("End");
			})
		;
		this.socket
			.listener("open sync dialog")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.syncDialogOpen = true;
			})
		;
		this.socket
			.listener("close sync dialog")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.syncDialogOpen = false;
			})
		;
	}

	makeForm() {
		this.adminForm = this.fb.group({
			status: new FormControl(this.quiz.status['status'], [])
		})
	}

	toQuestion(action) {
		let questionNr = action == "next" ? this.quiz.status['question'] + 1 : this.quiz.status['question'] - 1;
		if(
			(action == "next" && this.quiz.status['question'] <= this.round['questions']) ||
			(action == "previous" && this.quiz.status['question'] > 0)
		) {
			this.socket.emit("admin to question", {question: questionNr});
		}
	}

	async toRound(action) {
  
		let nextRound = null;
		if(action == "next") {
			if(this.round['order'] >= this.quiz.rounds.length) {
				// end the quiz if this is the last round
				this.socket.emit("admin end quiz", {});
			} else {
				nextRound = this.quiz.rounds.filter(obj => { return obj['order'] == this.round['order'] + 1; })[0];
			}
		}

		if(action == "previous") {
			if(this.round['order'] <= 1) {
				// do nothing if this is the first round
				return;
			}
			nextRound = this.quiz.rounds.filter(obj => { return obj['order'] == this.round['order'] - 1; })[0];
		}
  
		if(nextRound !== null) {
			this.socket.emit("admin to round", {round: nextRound['id']});
		}
	}
  
	changeServerStatus() {
		let status = this.adminForm.get('status').value;
	  	this.socket.emit("admin change status", {status: status});
	}

	openSyncDialog() {
		this.socket.emit("admin open sync dialog", {});
	}

	closeSyncDialog() {
		this.socket.emit("admin close sync dialog", {});
	}

	clearSyncDelay() {
		this.socket.emit("admin clear sync", {});
	}

	setServerSyncTime() {
		if(this.syncDialogOpen) {
			var time = new Date().getTime();
			this.socket.emit("admin serverSyncTime", {time: time});
			this.syncDialogOpen = false;
		}
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
	  }
}
