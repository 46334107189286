import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { EventEmitterService } from 'src/app/services/event-emitter/event-emitter.service';
import { FormDialogComponent } from '../../helpers/dialogs/form-dialog/form-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { takeUntil } from 'rxjs/operators';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	
	private onDestroy$	: Subject<void> = new Subject<void>();
	resetRequestDialog	: FormGroup;
	dialogRef           : MatDialogRef<FormDialogComponent>
	formDialog;
	
	constructor(
		private fb                 : FormBuilder,
		private api  			   : ApiService,
		private dialog             : MatDialog,
		private eventEmitterService: EventEmitterService,
		private quiz 			   : QuizService,
	) { }
	
	ngOnInit(): void {
		this.makeForm();
		this.openDialog();
		this.eventEmitterService.startListening();

		if (
			this.eventEmitterService.subsVar==undefined ||
			this.eventEmitterService.subsVar==null
		) {   
			
			this.eventEmitterService.subsVar = 
				this
				.eventEmitterService
				.invokeFirstComponentFunction
				.pipe(takeUntil(this.onDestroy$))
				.subscribe((name:string) => {    
					this.sendResetMail();    
				})
			;
		}  
	}

	openDialog():Promise<boolean> {
		var thisComponent = this;
		return new Promise(function(resolve, reject) {
			thisComponent.dialogRef = thisComponent.dialog.open(FormDialogComponent,{
				data:{
					title       : 'Wachtwoord vergeten',
					text        : 'Vul hieronder je e-mailadres in. We sturen je instructies om je wachtwoord te resetten.',
					doneText    : 'Kijk in je mailbox voor instructies om je wachtwoord te resetten.',
					doneBtnText : 'Inloggen',
					doneBtnLink : '/login',
					formGroup : thisComponent.resetRequestDialog,
					formFields: [
						{
							'label'       : 'E-mailadres',
							'formControl' : 'email',
							'type'        : 'text'
						},
					],
					buttonText	: 'Verstuur',
					submit 		: thisComponent.sendResetMail,
					links 		: {"url": "/login", "text": "Inloggen"},
					customCss   : "max-width: 350px;"
				}
			});
			thisComponent.formDialog = thisComponent.dialogRef;
		})
	}

	
	makeForm() {
		this.resetRequestDialog = this.fb.group({
			email: new FormControl('', [
				Validators.required,
				Validators.minLength(3),
				Validators.email
			]),
		});
	}

	async sendResetMail() {
		this.dialogRef.componentInstance.sent = true;
		await this.api.request("post", "/user/send_reset_mail", {
			body: {
				host    : environment.HOST,
				email	: this.resetRequestDialog.get('email').value,
				quizId	: this.quiz.id
			}
		})
		// this.resetRequestDialog = this.fb.group({});
	}

	public ngOnDestroy(): void {
		this.eventEmitterService.subsVar = null;
		this.dialog.closeAll();
		this.onDestroy$.next();
	}
	
}
