import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-messages',
	templateUrl: './messages.component.html',
	styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {
	
	messages		   : object[];
	enabled		       : boolean;
	speed  		       : number;
	amount 		       : number;
	isLoading		   : boolean 		= true;
	enabled_loading    : boolean 		= true;
	speed_loading      : boolean 		= true;
	amount_loading     : boolean 		= true;
	displayedColumns   : string[] 		= ["name", "message", "action"];
	private onDestroy$: Subject<void> 	= new Subject<void>();

	constructor(
		private api: ApiService,
		private quiz: QuizService,
		private socket: WebSocketService
	) { }
	
	async ngOnInit() {
		await this.getMessages();
		this.listenForMessages();
		this.listenForSettings();
		this.setSettings();
		this.isLoading = false;
	}

	getMessages() {
		return new Promise(async (res, rej) => {
			this.messages = await <Promise<object[]>>this.api.request("get", "messages/" + this.quiz.id);
			res();
		});
	}

	getMessageWithStatus(status) {
		return this.messages.filter(message => {
			return message['approved'] == status;
		})
	}

	setSettings() {
		this.enabled = this.quiz.settings['MESSAGES_ENABLED'] == 1 ? true : false;
		this.speed   = parseFloat(this.quiz.settings['MESSAGES_SPEED']) * 10;
		this.amount  = parseInt(this.quiz.settings['MESSAGES_AMOUNT']);

		this.enabled_loading = false;
		this.speed_loading   = false;
		this.amount_loading  = false;
	}

	async reviewMessage(message, status) {
		let update = await this.api.request("put", "messages/"+message, {body: {approved: status}});
		if(!update['errors']) {
			this.socket.emit("messages_updated", {});
		}
	}

	listenForMessages() {
		this.socket
			.listener("update_messages")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.getMessages();
			})
		;
	}

	listenForSettings() {
		this.socket
			.listener("update_settings")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.quiz.settings = <object[]>data;
			})
		;
	}

	formatLabel(value: number) {
		return value / 10;
	}

	async updateEnabled(event) {
		this.enabled_loading = true;
		var speed = await this.api.request("put", "/settings/"+this.quiz.id+"/MESSAGES_ENABLED", {body: {value: event.checked}});
		this.enabled = speed['data']['value'];
		this.socket.emit("settings_updated", {});
		this.enabled_loading = false;
	}

	async updateAmount(action) {
		if(action == "plus" || (action == "minus" && this.amount > 4)) {
			var newAmount       = (action == "plus") ? (<number>this.amount + 1) : (<number>this.amount - 1);
			this.amount_loading = true;
			var amount          = await this.api.request("put", "/settings/"+this.quiz.id+"/MESSAGES_AMOUNT", {body: {value: newAmount}});
			this.amount 		= amount['data']['value'];
			this.socket.emit("settings_updated", {});
			this.amount_loading = false;
		}
	} 

	async updateSpeed(event) {
		this.speed_loading = true;
		var speed = await this.api.request("put", "/settings/"+this.quiz.id+"/MESSAGES_SPEED", {body: {value: (event.value / 10)}});
		this.speed = parseFloat(speed['data']['value']) * 10;
		this.socket.emit("settings_updated", {});
		this.speed_loading = false;
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
	}
}
