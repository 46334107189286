import { Component, OnInit } from '@angular/core';
import { FormDialogComponent } from '../../helpers/dialogs/form-dialog/form-dialog.component';
import { Validators, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitterService } from 'src/app/services/event-emitter/event-emitter.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Subject } from 'rxjs';
import { validatorMatchWith } from '../../../validators/isEqualTo.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

	private onDestroy$	: Subject<void> = new Subject<void>();
	signUpForm			    : FormGroup;
	formDialog;
	
	constructor(
		private fb                 : FormBuilder,
		private router             : Router,
		private dialog             : MatDialog,
		private eventEmitterService: EventEmitterService,
		private authService	       : AuthenticationService
	) { }
	
	ngOnInit() {
		
		this.makeForm();
		this.openDialog();
		this.eventEmitterService.startListening();

		// make shure the form dialog can access the "registerUser function"
		if (this.eventEmitterService.subsVar == undefined ||
			this.eventEmitterService.subsVar==null
		) {   
			this.eventEmitterService.subsVar = 
			this
			.eventEmitterService
			.invokeFirstComponentFunction
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((name:string) => {    
				this.register();    
			});
		}  
	}
	
	public ngOnDestroy(): void {
	this.eventEmitterService.subsVar = null;
    this.dialog.closeAll();
		this.onDestroy$.next();
	}
	
	makeForm() {
		this.signUpForm = this.fb.group({
			username: new FormControl('', [
				Validators.required,
				Validators.minLength(3)
			]),
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(7)
			]),
			password_confirm: new FormControl('', [
				Validators.required,
				Validators.minLength(7),
				validatorMatchWith('password')
			]),
			email: new FormControl('', [
				Validators.required,
				Validators.email
			]),
		});
	}
	
	async register() {
		if(this.signUpForm.valid) {
			let username      = this.signUpForm.controls.username.value;
			let password      = this.signUpForm.controls.password.value;
			let email      	  = this.signUpForm.controls.email.value;

			try {
				var res = await this.authService.signup(username, password, email);
				if(!res['errors']) {
					this.dialog.closeAll();
					this.router.navigateByUrl("/");
				}
			} catch(err) {
				// default handler
			}
		}
	}
	
	openDialog():Promise<boolean> {
		var thisComponent = this;
		return new Promise(function(resolve, reject) {
			const dialogRef = thisComponent.dialog.open(FormDialogComponent,{
				data:{
					title     : 'Registreren',
					text      : `Maak een account aan om mee te doen aan de quiz.`,
					formGroup : thisComponent.signUpForm,
					formFields: [
						{
							'label'       : '(Team) naam',
							'formControl' : 'username',
							'type'        : 'text'
						},
						{
							'label'       : 'Wachtwoord',
							'formControl' : 'password',
							'type'        : 'password'
						},
						{
							'label'       : 'Wachtwoord (bevestigen)',
							'formControl' : 'password_confirm',
							'type'        : 'password'
						},
						{
							'label'       : 'E-mailadres',
							'formControl' : 'email',
							'type'        : 'text'
						},
					],
					buttonText	: 'Registreren',
					submit 		: thisComponent.register,
					links 		: {"url": "/login", "text": "Inloggen"}
				}
			});
			thisComponent.formDialog = dialogRef;
		})
	}
}
