import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-sync-dialog',
	templateUrl: './sync-dialog.component.html',
	styleUrls: ['./sync-dialog.component.scss']
})
export class SyncDialogComponent implements OnInit, OnDestroy {
	
	syncDone: boolean = false;
	reactionTimeout;
	countdownInterval;
	countdown = 0;
	private onDestroy$	: Subject<void> = new Subject<void>();

	constructor(
		public socket: WebSocketService
	) { }
	
	ngOnInit(): void {
		this.syncDone = false;
		this.socket
			.listener("set serverSyncTime")
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((data) => {
				this.reactionTimeout = setTimeout(() => {
					this.syncApp();
				}, 60000);
				setTimeout(() => {
					if(!this.syncDone) {
						this.startCountdown();
					}
				}, 30000);
			})
		;
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	
	startCountdown() {
		this.countdown = 30;
		this.countdownInterval = setInterval(() => {
			this.countdown--;
			if(this.countdown == 0) {
				clearInterval(this.countdownInterval);
			}
		}, 1000);
	}

	syncApp() {
		clearTimeout(this.reactionTimeout);
		clearInterval(this.countdownInterval);
		this.countdown = 0;
		if(this.socket.serverSyncTime > 0) {
			this.socket.sync();
			this.syncDone = true;
			setTimeout(() => {
				this.socket.closeSyncDialog();
			}, 4000);
		}
	}
}
