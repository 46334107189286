<h2>Nakijken per vraag</h2>

<mat-accordion>
	<mat-expansion-panel class='mat-expansion-panel--no-padding' *ngFor="let round of quiz.rounds">
		<mat-expansion-panel-header (click)="getQuestions(round.id, round.title)">
			<mat-panel-title>
				<b>{{round.title}}</b>
			</mat-panel-title>
			<mat-panel-description>
				<!-- <div class='countTeams'>0</div> -->
			</mat-panel-description>
		</mat-expansion-panel-header>

		<table 
			mat-table 
			[dataSource]="questions[round.id + '_' + round.title]" 
			class="full-width mat-table-alternate mat-table-row-links mat-elevation-z0"
			*ngIf="!this.isLoading && questions[round.id + '_' + round.title].length > 0"
        >
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef> Vraag </th>
				<td mat-cell *matCellDef="let element"> {{element.question}} </td>
			</ng-container>

			<ng-container matColumnDef="status">
				<th class='nakijken__status-cel' mat-header-cell *matHeaderCellDef></th>
				<td class='nakijken__status-cel' mat-cell *matCellDef="let element"> 
					<div 
						*ngIf="getStatus(element.reviewed, element.total) == 'new'" 
						class="nakijken__status nakijken__status--new"
						title="Ronde nog niet nagekeken" 
					></div>
					<div 
						*ngIf="getStatus(element.reviewed, element.total) == 'inProgress'" 
						class="nakijken__status nakijken__status--inProgress" 
						title="{{element.reviewed}} van de {{element.total}} vragen nagekeken"
					></div>
					<div 
						*ngIf="getStatus(element.reviewed, element.total) == 'done'" 
						class="nakijken__status nakijken__status--done"
						title="Ronde volledig nagekeken" 
					></div>
				</td>
			</ng-container>

			<ng-container matColumnDef="points">
				<th class="points" mat-header-cell *matHeaderCellDef> Punten </th>
				<td class="points" mat-cell *matCellDef="let element">
					<span *ngIf="element.reviewed == round.questions">{{ element.points || 0 }}</span>
					<span *ngIf="element.reviewed < round.questions"><i>{{ element.points || 0 }}</i></span>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="/admin/nakijken/vraag/{{round.id}}/{{row.number}}/"></tr>

		</table>
		<!-- <div 
			class="noItemsFound"
			*ngIf="!isLoading && users[round.id + '_' + round.title].length == 0"
		>Geen resultaten gevonden</div> -->
	</mat-expansion-panel>
</mat-accordion>