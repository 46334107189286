import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormDialogComponent } from '../../helpers/dialogs/form-dialog/form-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitterService } from 'src/app/services/event-emitter/event-emitter.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

	private onDestroy$	: Subject<void> = new Subject<void>();
	signUpForm			: FormGroup;
	formDialog;
	
	constructor(
		private fb                 : FormBuilder,
		private router             : Router,
		private dialog             : MatDialog,
		private eventEmitterService: EventEmitterService,
		private authService	       : AuthenticationService
	) { }
	
	ngOnInit() {
		
		this.makeForm();
		this.openDialog();
		this.eventEmitterService.startListening();
		// make shure the form dialog can access the "login function"
		if (
			this.eventEmitterService.subsVar==undefined ||
			this.eventEmitterService.subsVar==null
		) {   
			
			this.eventEmitterService.subsVar = 
				this
				.eventEmitterService
				.invokeFirstComponentFunction
				.pipe(takeUntil(this.onDestroy$))
				.subscribe((name:string) => {    
					this.login();    
				})
			;
		}  
	}
	
	public ngOnDestroy(): void {
		this.eventEmitterService.subsVar = null;
		this.dialog.closeAll();
		this.onDestroy$.next();
	}
	
	makeForm() {
		this.signUpForm = this.fb.group({
			username: new FormControl('', [
				Validators.required,
				Validators.minLength(3)
			]),
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(7)
			]),
		});
	}
	
	async login() {
		if(this.signUpForm.valid) {
			let username = this.signUpForm.controls.username.value;
			let password = this.signUpForm.controls.password.value;

			try {
				var res = await this.authService.login(username, password);
				if(!res['error']) {
					this.dialog.closeAll();
					this.router.navigateByUrl("/");
				} else {
				}
			} catch(err) {
				// default handler
			}
		}
	}
	
	openDialog():Promise<boolean> {
		var thisComponent = this;
		return new Promise(function(resolve, reject) {
			const dialogRef = thisComponent.dialog.open(FormDialogComponent,{
				data:{
					title     : 'Inloggen',
					text      : 'Log in om deel te nemen aan de quiz',
					formGroup : thisComponent.signUpForm,
					formFields: [
						{
							'label'       : 'Email of (Team) naam',
							'formControl' : 'username',
							'type'        : 'text'
						},
						{
							'label'       : 'Wachtwoord',
							'formControl' : 'password',
							'type'        : 'password'
						},
					],
					buttonText	: 'Inloggen',
					hintUrl		: '/wachtwoord_vergeten',
					hintText	: 'Wachtwoord vergeten',
					submit 		: thisComponent.login,
					links 		: {"url": "/inschrijven", "text": "Registreren"},
				}
			});
			thisComponent.formDialog = dialogRef;
		})
	}
}
