import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-select-quiz',
	templateUrl: './select-quiz.component.html',
	styleUrls: ['./select-quiz.component.scss']
})
export class SelectQuizComponent implements OnInit, OnDestroy {
	
	private unsubscribe = new Subject;
	
	constructor(
		private activatedRoute: ActivatedRoute,
		private api: ApiService,
		private snackbar: MatSnackBar,
		private router: Router
	) { }
	
	ngOnInit(): void {
		this.selectQuiz();
	}

	selectQuiz() {
		this.activatedRoute.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(async (params) => {
			var quizUrl = params.get("quizUrl");
			var quiz = await this.api.request("get", "quiz/url/"+quizUrl);
			if(!quiz) {
				this.snackbar.open("Geen quiz gevonden met deze URL", 'sluiten');
			} else {
				sessionStorage.setItem("quizId", quiz['id']);
				this.router.navigateByUrl("/");
			}
		});
	}
	
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
	
}
	