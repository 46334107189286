<mat-sidenav-container>
    <mat-sidenav #sideNav mode="over" [opened]="isSideNavOpen">
        <mat-nav-list>
            <!-- hacky woraround to prevent first item from focus -->
            
            <a mat-list-item
                routerLink="/"
                style="position: absolute; height: 0"
            ></a>

            <a mat-list-item
               (click)="sideNav.toggle()"
               routerLink="/"
               routerLinkActive="active-link"
               [routerLinkActiveOptions]="{exact: true}"
            ><span class="material-icons quiz__menu-icon">contact_support</span> Quiz </a>
            <a mat-list-item
               (click)="sideNav.toggle()"
                routerLink="/gebruiker/wijzigen"
                routerLinkActive="active-link"
                [routerLinkActiveOptions]="{exact: true}"
            ><span class="material-icons quiz__menu-icon">face</span> Account </a>
            <a mat-list-item
               (click)="isMessageOpen = !isMessageOpen; sideNav.toggle()"
               *ngIf="settings.MESSAGES_ENABLED"
            ><span class="material-icons quiz__menu-icon">chat_bubble_outline</span> Bericht sturen </a>
            <a 
                mat-list-item 
                (click)="logout(); sideNav.toggle()" 
                [routerLink]="'/'"
            ><span class="material-icons quiz__menu-icon">exit_to_app</span> Uitloggen </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="log_out">
            <button 
                class="quiz__menu-toggle-button"
                mat-icon-button 
                *ngIf="!authService.isTokenExpired()" 
                (click)="sideNav.toggle()"
            >
                <span class="material-icons">
                    menu
                </span>
            </button>
        </div>
        <div class="quiz__background"></div>
        <div [class.hidden]="quiz.status['status'] != 'Live' || quiz.status['question'] == 0" class="quiz__background quiz__background--grey"></div>
        <div class="quiz__wrapper" *ngIf="!isLoading">
            <div
                *ngIf="isMessageOpen" 
                class="quiz__wrapper-message"
                [@slideInOut]="animation"
            >
                <app-messages-form (onHide)="changeHide($event)"></app-messages-form>
            </div>
            <div 
                [class.quiz__wrapper-game--half]="isMessageOpen"
                class="quiz__wrapper-game"
            >
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>