import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService, QuizRoles } from 'src/app/services/authentication/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class QuizRoleGuard implements CanActivate {
	
	constructor(
		private authService: AuthenticationService,
		private snackbar: MatSnackBar,
		private router: Router
		) {}
		
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		let requiredRole = <QuizRoles>next.data.role;
		let userQuizRole = this.authService.getQuizRole();
		if(userQuizRole >= requiredRole) {
			return true;
		}
		this.snackbar.open("Je hebt niet voldoende rechten", 'Sluiten', {duration: 3000});
		this.router.navigate(['/admin']);	
		return false;
	}
			
}
		